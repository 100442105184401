<script>
import { required, integer } from "vuelidate/lib/validators";
import CalendarApiService from "@/services/calendarService";
export default {
  model: {
    prop: "appointments",
  },
  props: ["appointments"],
  components: {},
  data() {
    return {};
  },
  created() {
	},
  beforeDestroy() {},
  computed: {},
  methods: {
    validate() {
      this.$v.appointments.$touch();
      if (!this.$v.appointments.$invalid) {
        return true;
      }
      return false;
    },
    reset() {
      this.$v.$reset();
    },
    loadAppointments(model) {
      this.reset();
      return new Promise((resolve, reject) => {
        CalendarApiService.generateProgressAppointmentsReviewList(model).then(
          (response) => {
            var appointments = response.data.data;
            for (let app of appointments) {
              app.checked = true;
            }
            let appointmentList = appointments.sort((a, b) => {
              var aStartDate = this.$dayjs(a.startDate);
              var bStartDate = this.$dayjs(b.startDate);
              return bStartDate.isBefore(aStartDate) ? 1 : -1;
            });
            resolve(appointmentList);
          },
          (error) => reject(error)
        );
      });
    },
    loadCopyAppointments(model, copyWorkOrderIdPublic) {
      this.reset();
      return new Promise((resolve, reject) => {
        CalendarApiService.generateCopyProgressAppointmentsReviewList(
          model,
          copyWorkOrderIdPublic
        ).then(
          (response) => {
            var appointments = response.data.data;
            for (let app of appointments) {
              app.checked = true;
            }
            let appointmentList = appointments.sort((a, b) => {
              var aStartDate = this.$dayjs(a.startDate);
              var bStartDate = this.$dayjs(b.startDate);
              return bStartDate.isBefore(aStartDate) ? 1 : -1;
            });
            resolve(appointmentList);
          },
          (error) => reject(error)
        );
      });
    },
    reloadAppointments(model) {
      this.reset();
      return new Promise((resolve, reject) => {
        CalendarApiService.regenerateProgressAppointmentsReviewList(
          model,
          this.appointments
        ).then(
          (response) => {
            var appointments = response.data.data;
            for (let app of appointments) {
              app.checked = true;
            }
            let appointmentList = appointments.sort((a, b) => {
              var aStartDate = this.$dayjs(a.startDate);
              var bStartDate = this.$dayjs(b.startDate);
              return bStartDate.isBefore(aStartDate) ? 1 : -1;
            });
            resolve(appointmentList);
          },
          (error) => reject(error)
        );
      });
    },
  },
  validations: {
    appointments: {
      $each: {
        startDate: { required },
        numberOfDaysDefault: { required, integer },
      },
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-4">
        {{ $t("general.progressEventsOverview") }}
      </h5>
      <div class="table-responsive">
        <table class="table" style="height: 1px">
          <thead class="thead-light">
            <tr>
              <th>{{ $t("general.progress") }}</th>
              <th>{{ $t("general.days") }}</th>
              <th style="text-align: center">
                {{ $t("general.startDate") }}
              </th>
              <th style="text-align: center">
                {{ $t("general.capacity") }}
              </th>
              <th style="width: 100px; text-align: center">
                {{ $t("general.use") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="appointments.length != 0">
            <tr
              v-for="(appointment, index) in $v.appointments.$each.$iter"
              :key="index"
            >
              <td style="vertical-align: middle">
                <div
                  class="mr-3"
                  style="
										display: inline-block;
										vertical-align: middle;
										width: 20px;
										height: 20px;
										border-radius: 100px;
									"
                  :style="{
                    background: appointment.$model.progress.colorHexa,
                  }"
                ></div>
                <span style="vertical-align: middle">{{
                  appointment.$model.progress.name
                }}</span>
              </td>
              <td>
                <b-form-group>
                  <b-form-input
                    type="number"
                    @change="$emit('revalidate')"
                    v-model="appointment.numberOfDaysDefault.$model"
                    :class="{
                      'is-invalid':
                        $v.appointments.$error && appointment.$error,
                    }"
                    placeholder="0"
                    style="width: 60px; height: 40px"
                  >
                  </b-form-input>
                  <div
                    v-if="$v.appointments.$error && appointment.$error"
                    class="invalid-feedback"
                  >
                    <span>{{ $t("general.requiredValue") }}</span>
                  </div>
                </b-form-group>
              </td>
              <td
                style="vertical-align: middle; text-align: center"
                :style="[
                  $dayjs(appointment.$model.startDate).isBefore($dayjs(), 'day')
                    ? { color: 'rgb(244, 106, 106)' }
                    : { color: '' },
                ]"
              >
                <b-form-group class="required" style="position: relative">
                  {{ $dayjs(appointment.$model.startDate).format("L") }}
                  <div
                    v-if="$v.appointments.$error && appointment.$error"
                    class="invalid-feedback"
                  >
                    <span>{{ $t("general.requiredValue") }}</span>
                  </div>
                </b-form-group>
              </td>
              <td style="vertical-align: middle; text-align: center">
                <i
                  :id="'tooltip-' + index"
                  v-if="
                    appointment.$model.isOverCapacityData.isOverCapacity ==
                      false
                  "
                  style="font-size: 1.8em; color: rgb(52, 195, 143)"
                  class="mdi mdi-check-circle-outline"
                ></i>
                <i
                  :id="'tooltip-' + index"
                  v-if="
                    appointment.$model.isOverCapacityData.isOverCapacity == true
                  "
                  style="font-size: 1.8em; color: #f46a6a"
                  class="mdi mdi-alert-circle-outline"
                ></i>
                <b-tooltip :target="'tooltip-' + index" triggers="hover">
                  <ul
                    v-if="
                      JSON.stringify(
                        appointment.$model.isOverCapacityData.capacityData
                      ) != '{}'
                    "
                    style="
											margin-left: 0;
											list-style-type: none;
											padding-left: 0;
											text-align: left;
											margin-bottom: 0;
										"
                  >
                    <li
                      v-for="(capacityData, index) in appointment.$model
                        .isOverCapacityData.capacityData"
                      :key="index"
                      style="margin-left: 0; margin-bottom: 5px"
                    >
                      {{ $t("general." + index) }}:
                      {{ capacityData.totalValue }} /
                      {{ capacityData.capacity }}
                    </li>
                  </ul>
                </b-tooltip>
              </td>
              <td style="text-align: center; height: 100%">
                <div
                  style="
										height: 100%;
										width: 100%;
										display: flex;
										flex-direction: column;
										align-items: center;
										justify-content: center;
									"
                >
                  <b-form-checkbox
                    v-model="appointment.$model.checked"
                    class="custom-checkbox"
                  ></b-form-checkbox>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
