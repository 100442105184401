var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_vm._l((_vm.menuItems),function(item){return [(
          !item.isTitle &&
            !item.isLayout &&
            (item.meta.authRequired !== true || (item.meta.authRequired && _vm.$msal.currentAccount !== null && _vm.$msal.currentAccount !== undefined)) &&
            _vm.userCanAccessRoute(item) &&
            _vm.userHasOptionNeeded(item) &&
            _vm.userHasPermissionsForMenuItem(item)
        )?_c('li',{key:item.id,on:{"mouseover":_vm.hoverItem,"mouseleave":_vm.leaveItem}},[(_vm.hasItems(item))?_c('a',{staticClass:"is-parent",class:{ 'has-arrow': !item.badge, 'has-dropdown': item.badge },attrs:{"href":"javascript:void(0);"}},[(item.icon)?_c('i',{class:("bx " + (item.icon))}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),(!_vm.hasItems(item))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":item.link}},[(item.icon)?_c('i',{class:("bx " + (item.icon))}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.label))+" "),(
                _vm.alerts &&
                  _vm.alerts.NoTemplateCount &&
                  _vm.alerts.NoTemplateCount > 0 &&
                  item.link === '/workOrders'
              )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"border-left border-danger pl-2 ml-1 text-danger",staticStyle:{"font-size":"small"},attrs:{"title":_vm.$t('general.workOrdersWithoutTemplate')}},[_vm._v(" "+_vm._s(_vm.alerts.NoTemplateCount)+" "),_c('i',{staticClass:"bx bx-notification-off bx-xs",staticStyle:{"color":"#F46A6A !important"}})]):_vm._e()]),(item.badge)?_c('span',{class:("badge badge-pill badge-" + (item.badge.variant) + " float-right")},[_vm._v(_vm._s(_vm.$t(item.badge.text)))]):_vm._e()]):_vm._e(),(_vm.hasItems(item))?_c('ul',{staticClass:"sub-menu",attrs:{"aria-expanded":"false"}},_vm._l((item.subItems),function(subitem,index){return _c('li',{key:index},[(_vm.userHasOptionNeeded(subitem))?_c('div',[(!_vm.hasItems(subitem))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subitem.link}},[_vm._v(_vm._s(_vm.$t(subitem.label)))]):_vm._e(),(_vm.hasItems(subitem))?_c('a',{staticClass:"side-nav-link-a-ref has-arrow",attrs:{"href":"javascript:void(0);"}},[_vm._v(" "+_vm._s(_vm.$t(subitem.label))+" ")]):_vm._e(),(_vm.hasItems(subitem))?_c('ul',{staticClass:"sub-menu mm-collapse",attrs:{"aria-expanded":"false"}},_vm._l((subitem.subItems),function(subSubitem,index){return _c('li',{key:index},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":subSubitem.link}},[_vm._v(_vm._s(_vm.$t(subSubitem.label)))])],1)}),0):_vm._e()],1):_vm._e()])}),0):_vm._e()],1):_vm._e()]}),(
        (_vm.currentRouteName == 'default' || _vm.currentRouteName == 'workOrders') &&
          _vm.userCanCreateWorkOrder
      )?_c('li',[_c('a',{staticClass:"side-nav-link-ref",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$root.$emit('create-workorder')}}},[_c('i',{staticClass:"bx bx-plus"}),_c('span',[_vm._v(_vm._s(_vm.$t("general.createWorkOrder")))])])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }