
<script>
import WorkOrderDetailForm from "./workorder-detail-form";
export default {
    components: {
        WorkOrderDetailForm
    },
    data() {
        return {
            showModal: false,
            state: "create",
        };
    },
    created() {
    },
    mounted() {
        this.$root.$on("create-workorder", this.createWorkOrder);
        this.$root.$on("copy-workorder", this.copyWorkOrder);
        this.$root.$on("edit-workorder", this.editWorkOrder);
    },
    beforeDestroy() {
        this.$root.$off("create-workorder", this.createWorkOrder);
        this.$root.$off("copy-workorder", this.copyWorkOrder);
        this.$root.$off("edit-workorder", this.editWorkOrder);
    },
    methods: {
        createWorkOrder() {
            this.state = "create";
            this.showModal = true;
            this.$refs.workorderform.createWorkOrder().then(() => {
                this.$root.$emit("save-workorder");
            }).finally(() => {
                this.showModal = false;
            });
        },
        copyWorkOrder(workOrderIdPublic) {
            this.state = "create";
            this.showModal = true;
            this.$refs.workorderform.copyWorkOrder(workOrderIdPublic).then(() => {
                this.$root.$emit("save-workorder");
            }).finally(() => {
                this.showModal = false;
            });
        },
        editWorkOrder(workOrderIdPublic) {
            this.state = "edit";
            this.showModal = true;
            this.$refs.workorderform.editWorkOrder(workOrderIdPublic).then(() => {
                this.$root.$emit("save-workorder");
            }).finally(() => {
                this.showModal = false;
            });
        },
    },
    computed: {

    },
};
</script>
<template>
    <b-modal v-model="showModal" :title="
    state == 'create'
        ? $t('general.workOrderCreationWizard')
        : $t('general.workOrderEditionWizard')
    " title-class="text-black font-18" hide-footer body-class="p-3" size="lg" no-close-on-backdrop static>
        <WorkOrderDetailForm ref="workorderform" />
    </b-modal>
</template>