<script>
// eslint-disable-next-line no-unused-vars
import CalendarApiService from "@/services/calendarService";

export default {
  model: {
    prop: "assignedUsers",
    event: 'change'
  },
  components: {},
  props: {
    assignedUsers: {
      type: Array,
    },
    users: {
      type: Array,
    },
    placeholder: String,
    trackBy: {
      type: String,
      default: "id",
    },
		label: {
			type: String,
			default: "displayName"
		}
  },
  data() {
    return {
      isLoading: false,
      debounceTimeout: null,
    };
  },
  created() {},
  computed: {
    assignable_users() {
      return this.users.filter((user) => {
        return this.internal_assigned_users.indexOf(user) === -1;
      });
    },
    placeholderComputed() {
      return this.placeholder == null || typeof this.placeholder == "undefined"
        ? this.$t("general.assignedUsers")
        : this.placeholder;
    },
    internal_assigned_users() {
      return this.users.filter((x) =>
        this.assignedUsers.includes(x[this.trackBy])
      );
    },
  },
  watch: {},
  methods: {
    addUser(users) {
			this.assignedUsers.splice(0, this.assignedUsers.length);
      this.assignedUsers.push(...users.map((x) => x[this.trackBy]));
      this.$emit("change", this.assignedUsers);
    },
  },
};
</script>
<template>
  <multiselect
    :value="internal_assigned_users"
    @input="addUser"
    :options="assignable_users"
    :placeholder="placeholderComputed"
    :label="label"
    :allow-empty="true"
    multiple
    :loading="isLoading"
    :track-by="trackBy"
    :show-labels="false"
  >
    <template slot="noOptions">{{ $t("general.noAssignableUser") }}</template>
    <template slot="noResult">{{ $t("general.noAssignedUser") }}</template>
  </multiselect>
</template>
