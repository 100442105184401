<script>
import EventDetailsModal from "@/components/event-details-modal";

export default {
  name: "app",
  page: {
    title: "Planning",
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      let translated = this.$t('general.' + title);
      return translated ? `${translated}` : 'Planification';
    }
  },
  components:{
    EventDetailsModal
  },
  data() {
    return {
      isLoadingConfig: true,
      opacity:0,
      lang: this.$i18n.locale
    };
  },  
  created(){
    this.$bus.$on('initial-loading-completed', this.initialLoadingCompleted);
  },   
  mounted() {
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
    }
  },
  methods: {
    initialLoadingCompleted(){
      this.isLoadingConfig = false;
    }
  }
};
</script>

<template>
  <div id="app">
    <div v-if="isLoadingConfig" id="initLoadingOverlay" style="flex-direction: column; display:flex; align-items:center; justify-content:center; background-color:rgb(255, 255, 255); z-index:9999; position:absolute; top:0; bottom:0; left:0; right:0;">
      <loading 
        :active.sync="isLoadingConfig" 
        :can-cancel="false"
        color='#f1b44c'
        :width=60
        :opacity="opacity"
        :height=60
        loader='dots'
        :is-full-page="false"></loading>

        <p class="text-muted mt-0">{{$t('general.loadingUserConfigText')}}</p> 
    </div>

    <RouterView />
    <EventDetailsModal ref="eventDetailsModal" />
  </div>
</template>
