
<script>
import CalendarApiService from "@/services/calendarService";
import ConfirmReplaceProgressValuesModal from "./confirm-replace-progress-values-modal";
export default {
    model: {
        prop: "workordervalues",
    },
    props: ["workordervalues"],
    components: {
        ConfirmReplaceProgressValuesModal
    },
    data() {
        return {
            template: null,
            workordersteps: null,
            generateFromTemplate: false,
            isCopy: false
        };
    },
    created() {

    },
    beforeDestroy() {

    },
    computed: {
        tableFields() {
            return [
                {
                    key: "measureType",
                    label: "",
                    thClass: "col-auto",
                    tdClass: "col-auto align-middle",
                },
                {
                    key: "value",
                    label: "",
                    editable: true,
                    thClass: "col-5",
                    tdClass: "col-5 align-middle",
                },
                {
                    key: "actions",
                    label: "",
                    thClass: "col-1",
                    tdClass: "col-1 align-middle",
                },
            ];
        },
        workOrderValues() {
            return this.workordervalues.filter(x => x.value !== null && x.value !== "").map(v => {
                return {
                    measureType: v.measureType, value: v.value, idPublic: v.idPublic
                };
            });
        },
        workOrderStepValues() {
            if (this.template == null) {
                return null;
            }
            var values = [];
            this.workordervalues.filter(x => x.value != null && x.value != "" && x.progress == null).forEach(v => {
                var workordervalues = this.generateProgressValues(v);
                values.push(...workordervalues?.map(p => {
                    return { measureType: v.measureType, idPublic: null, stepIdPublic: p.stepidpublic, value: v.value }
                }) ?? []);
            });
            values = values.concat(...this.workordervalues.filter(v => v.progress != null).map(v => {
                return v.progress.filter(p => p.value != null && p.value != "" && p.checked == true).map(p => {
                    return { measureType: v.measureType, idPublic: p.idpublic, stepIdPublic: p.stepidpublic, value: p.value };
                });
            }));
            if (values.length === 0) {
                return null;
            }
            return values;
        }
    },
    methods: {
        setData(templateIdPublic, workOrderIdPublic, generateFromTemplate, isCopy) {
            this.reset();
            this.generateFromTemplate = generateFromTemplate ?? true;
            this.isCopy = isCopy ?? false;
            return new Promise((resolve, reject) => {
                if (workOrderIdPublic == null) {
                    CalendarApiService.getTemplateDetail(templateIdPublic).then((templateResult) => {
                        this.template = templateResult.data.data;
                        resolve();
                    }, (error) => reject(error));
                } else {
                    var templatePromise = CalendarApiService.getTemplateDetail(templateIdPublic).then((templateResult) => {
                        this.template = templateResult.data.data;
                    }, (error) => reject(error));
                    var workorderstepPromise = CalendarApiService.getWorkOrderProgress(workOrderIdPublic).then((workorderstepresult) => {
                        this.workordersteps = workorderstepresult.data.data;
                    }, (error) => reject(error));
                    Promise.all([templatePromise, workorderstepPromise]).then(() => {
                        for (var workordervalue of this.workordervalues) {
                            var valueProgresses = this.generateProgressValues(workordervalue.measureType);
                            if (valueProgresses.filter(vp => vp.value !== null && vp.value !== "").length !== 0) {
                                workordervalue.progress = valueProgresses;
                            }
                        }
                        resolve();
                    });
                }
            });
        },
        applyToProgressValues(item) {
            this.setWorkOrderValueToStepValues(item);
            this.toggleDetails(item, false);
        },
        setWorkOrderValueToStepValues(item) {
            if (item.progress == null) {
                item.progress = this.generateProgressValues(item.measureType);
                item.progress.forEach((p) => {
                    p.value = item.value;
                    p.checked = p.value != null && p.value != "";
                });
            } else {
                this.$refs.confirmReplaceProgressValuesModal.show().then(() => {
                    item.progress.forEach((p) => {
                        p.value = item.value;
                        p.checked = p.value != null && p.value != "";
                    });

                })
            }
        },
        generateProgressValues(measureType) {
            var progressValues = [];
            var workorderstepvalues = this.workordersteps?.map(ws => {
                var progressValue = ws.values.find(v => v.measureType === measureType);
                var value = progressValue?.value ?? null;
                return { idpublic: this.isCopy ? null : progressValue?.idPublic ?? null, stepidpublic: ws.progress.stepIdPublic, name: ws.progress.name, value: value, checked: value !== null, order: ws.progress.order } ?? []
            });
            if (this.generateFromTemplate) {
                progressValues.push(...this.template.templateSteps.map(ts => { return { idpublic: null, stepidpublic: ts.progress.stepIdPublic, name: ts.progress.name, value: null, checked: false, order: ts.progress.order } }));

                for (var progressValue of progressValues) {
                    var workorderstepvalue = workorderstepvalues?.find(wsv => wsv.stepidpublic === progressValue.stepidpublic);
                    if (this.isCopy) {
                        progressValue.idpublic = workorderstepvalue?.idpublic ?? null;
                    }
                    progressValue.value = workorderstepvalue?.value ?? null;
                    progressValue.checked = workorderstepvalue?.checked ?? progressValue.value != null
                }
            } else {
                progressValues.push(...workorderstepvalues);
            }
            progressValues.sort((a, b) => a.order - b.order);
            return progressValues;
        },
        toggleDetails(item, toggleClose) {
            if (item._showDetails && toggleClose == true) {
                this.$set(item, '_showDetails', false)
                if (item.progress.filter(x => x.value !== null && x.value !== "").length == 0) {
                    item.progress = null;
                }
            } else {
                this.workordervalues.forEach(workordervalue => {
                    this.$set(workordervalue, '_showDetails', false);
                })

                this.$nextTick(() => {
                    this.$set(item, '_showDetails', true);
                    this.workordervalues.filter(x => x.progress !== null && x._showDetails === false).forEach(workordervalue => {
                        if (workordervalue.progress.filter(x => x.value !== null && x.value !== "").length == 0) {
                            workordervalue.progress = null;
                        }
                    });
                })
            }
        },
        reset() {
            this.template = null;
            this.workordersteps = null;
            this.generateFromTemplate = false;
            this.isCopy = false;
        },
        valueChange(item) {
            if (item.value == null || item.value == "") {
                item.checked = false;
            } else {
                item.checked = true;
            }
        },
        setValueNull(progress) {
            if (progress.checked == false) {
                progress.value = null;
            }
        }
    },
};
</script>
<template>
    <div>
        <div class="row">
            <div class="col-12 d-flex align-items-center" style="justify-content: space-between;">
                <h4> {{ $t("general.values") }} </h4>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <b-table :items="workordervalues" :fields="tableFields" thead-class="d-none" responsive ref="valueTable">
                    <template #cell(measureType)="row">
                        <span style="font-size: 1.2em;">{{ $t("general." + row.item.measureType) }}</span>
                    </template>
                    <template #cell(value)="row">
                        <div class="d-flex flex-row align-items-center">
                            <b-form-input class="m-1 ml-4" type="number" step="0.01" v-model="row.item.value" />
                            <b-button variant="success" class="m-1" @click="applyToProgressValues(row.item)">
                                <i class="mdi mdi-table-plus"></i>
                            </b-button>
                        </div>
                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex flex-row justify-content-end">
                            <i v-show="row.item.progress != null" @click="toggleDetails(row.item, true)" :class="{
                                mdi: true,
                                'mdi-36px': true,
                                'mdi-chevron-down': !row.detailsShowing,
                                'mdi-chevron-up': row.detailsShowing,
                            }">
                            </i>
                        </div>
                    </template>
                    <template #row-details="row">
                        <div class="d-flex flex-column">
                            <div v-for="(progress, index) in row.item.progress" :key="index">
                                <div class="d-flex flex-row align-items-center m-2" style="font-size:1.1em;">
                                    <div class="col-1">{{ index + 1 }} : </div>
                                    <div class="col-4 d-flex">
                                        <b-form-checkbox v-model="progress.checked" switch
                                            :disabled="progress.checked == false && (progress.value == null || progress.value === '')"
                                            @change="setValueNull(progress)" class="custom-checkbox"></b-form-checkbox>{{
                                                progress.name
                                            }}
                                    </div>
                                    <div class="col-3"><b-form-input type="number" step="0.01" v-model="progress.value"
                                            @change="valueChange(progress)" /></div>
                                </div>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <ConfirmReplaceProgressValuesModal ref="confirmReplaceProgressValuesModal" />
    </div>
</template>
<style scoped>
.has-progress-values {
    cursor: pointer;
    color: #556ee6;
}

.has-progress-values span {
    text-decoration: underline;
}

.has-progress-values i {
    text-decoration: none !important;
}
</style>