
<script>
import WorkOrderDetailInfo from "./workorder-detail-info";
import WorkOrderDetailValues from "./workorder-detail-values";
import WorkOrderDetailAppointments from "./workorder-detail-appointments";
import CalendarApiService from "@/services/calendarService";
import { FormWizard, TabContent } from "vue-form-wizard";
import ResetAppointmentsModal from "./reset-appointments-modal";

export default {
    components: {
        WorkOrderDetailInfo,
        WorkOrderDetailValues,
        WorkOrderDetailAppointments,
        ResetAppointmentsModal,
        FormWizard,
        TabContent,
    },
    data() {
        return {
            isLoading: false,
            workorderinfo: {},
            workordervalues: [],
            appointments: [],
            state: "create",
            originalTemplateIdPublic: null,
            saveFct: null,
            reCalculateTimoutId: null,
            beforeValidateInfo: null,
            beforeValidateValues: null,
        };
    },
    created() {
    },
    beforeDestroy() {
    },
    computed: {
        editAppointments() {
            return this.state === "create" || this.originalTemplateIdPublic != this.workorderinfo.template?.idPublic;
        },
    },
    methods: {
        creationBeforeValidateValues() {
            this.isLoading = true;
            return new Promise((resolve, reject) => {
                this.$refs.appointmentspanel.loadAppointments({
                    ...this.$refs.workorderinfopanel.getInfoModel(),
                    workOrderProgressValues: this.$refs.workordervaluespanel.workOrderStepValues,
                }).then((appointments) => {
                    this.appointments = appointments;
                    resolve(true);
                }, (error) => reject(error)).finally(() => {
                    this.isLoading = false;
                });
            });
        },
        creationBeforeValidateInfo() {
            if (!this.$refs.workorderinfopanel.validate()) {
                return false;
            }
            return new Promise((resolve, reject) => {
                this.$refs.workordervaluespanel.setData(this.workorderinfo.template.idPublic, null, true, false).then(() => {
                    resolve(true);
                }, (error) => reject(error)).finally(() => {
                    this.isLoading = false;
                });
            });
        },
        createWorkOrder() {
            return new Promise((resolve) => {
                this.state = "create";
                this.reset();
                this.loadDataNew();
                this.saveFct = resolve;
                this.beforeValidateInfo = this.creationBeforeValidateInfo;
                this.beforeValidateValues = this.creationBeforeValidateValues;
            });
        },
        copyWorkOrder(workOrderIdPublic) {
            return new Promise((resolve) => {
                this.state = "create";
                this.reset();
                this.loadDataWorkOrder(workOrderIdPublic, true);
                this.saveFct = resolve;
                this.beforeValidateInfo = () => {
                    if (!this.$refs.workorderinfopanel.validate()) {
                        return false;
                    }
                    return new Promise((resolve, reject) => {
                        this.isLoading = true;
                        this.$refs.workordervaluespanel.setData(this.workorderinfo.template.idPublic, workOrderIdPublic, false, true).then(() => {
                            resolve(true);
                        }, (error) => reject(error)).finally(() => {
                            this.isLoading = false;
                        });
                    });
                };
                this.beforeValidateValues = () => {
                    this.isLoading = true;
                    return new Promise((resolve, reject) => {
                        this.$refs.appointmentspanel.loadCopyAppointments({
                            ...this.$refs.workorderinfopanel.getInfoModel(),
                            workOrderProgressValues: this.$refs.workordervaluespanel.workOrderStepValues
                        }, workOrderIdPublic).then((appointments) => {
                            this.appointments = appointments;
                            resolve(true);
                        }, (error) => reject(error)).finally(() => {
                            this.isLoading = false;
                        });
                    });
                };
            });
        },
        editWorkOrder(workOrderIdPublic) {
            return new Promise((resolve) => {
                this.state = "edit";
                this.reset();
                this.loadDataWorkOrder(workOrderIdPublic, false);
                this.saveFct = resolve;
                this.beforeValidateInfo = () => {
                    if (!this.$refs.workorderinfopanel.validate()) {
                        return false;
                    }
                    this.isLoading = true;
                    return new Promise((resolve, reject) => {
                        this.$refs.workordervaluespanel.setData(this.workorderinfo.template.idPublic, this.workorderinfo.idPublic, this.editAppointments, false).then(() => {
                            resolve(true);
                        }, (error) => reject(error)).finally(() => {
                            this.isLoading = false;
                        });
                    });
                };
                this.beforeValidateValues = () => {
                    if (!this.editAppointments) {
                        return true;
                    }
                    this.isLoading = true;
                    return new Promise((resolve, reject) => {
                        this.$refs.appointmentspanel.loadAppointments({
                            ...this.$refs.workorderinfopanel.getInfoModel(),
                            workOrderProgressValues: this.$refs.workordervaluespanel.workOrderStepValues,
                        }).then((appointments) => {
                            this.appointments = appointments;
                            resolve(true);
                        }, (error) => reject(error)).finally(() => {
                            this.isLoading = false;
                        });
                    });
                };
            });
        },
        reset() {
            this.originalTemplateIdPublic = null;
            this.workorderinfo = {
                idPublic: null,
                name: null,
                template: null,
                jobType: null,
                workOrderGroup: null,
                numericValue: null,
                roomName: null,
                clientName: null,
                clientPhone: null,
                clientAddress: null,
                clientAddressLine2: null,
                clientCity: null,
                clientState: null,
                clientZipCode: null,
                clientEmail: null,
                productionDate: this.$dayjs().format("YYYY-MM-DD"),
                deliveryDate: this.$dayjs().format("YYYY-MM-DD"),
                deliveryEndDate: this.$dayjs().add(1, "day").format("YYYY-MM-DD"),
                installationDate: this.$dayjs().format("YYYY-MM-DD"),
                installationEndDate: this.$dayjs().add(1, "day").format("YYYY-MM-DD"),
                shipper: null,
                installers: [],
            };
            this.$refs.workorderinfopanel.reset();

            this.workordervalues = [];
            for (let measureType of this.measureTypes) {
                this.workordervalues.push({
                    idPublic: null,
                    measureType: measureType,
                    value: null,
                    progress: null,
                });
            }
            this.$refs.workordervaluespanel.reset();

            this.appointments = [];
            this.$refs.appointmentspanel?.reset();
            this.$refs.wizard.reset();
        },
        revalidateAppointments() {
            if (this.reCalculateTimoutId != null) {
                clearTimeout(this.reCalculateTimoutId);
            }
            if (this.$refs.appointmentspanel.validate()) {
                this.reCalculateTimoutId = setTimeout(() => {
                    this.isLoading = true;
                    this.$refs.appointmentspanel.reloadAppointments({
                        ...this.$refs.workorderinfopanel.getInfoModel(),
                        workOrderProgressValues: this.$refs.workordervaluespanel.workOrderStepValues,
                    }).then((appointments) => {
                        this.appointments = appointments;
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }, 500);
                return true;
            } else {
                return false;
            }
        },
        validateAppointments() {
            return this.$refs.appointmentspanel.validate();
        },
        saveNewWorkOrder() {
            this.isLoading = true;
            CalendarApiService.createWorkOrder({
                workOrder: {
                    ...this.workorderinfo,
                    workOrderValues: this.$refs.workordervaluespanel.workOrderValues,
                    workOrderProgressValues: this.$refs.workordervaluespanel.workOrderStepValues
                },
                progressAppointmentsReviewList:
                    this.appointments.length == 0
                        ? null
                        : this.appointments,
            }).then(
                (response) => {
                    if (response.data.success) {
                        this.trackEvent("CreateWorkOrder");
                        var msg = this.$t("general.workOrderCreationSuccessMsg");
                        msg = msg.replace("{title}", this.workorderinfo.name);
                        this.makeToast(this.$t("general.saved"), msg, "success");
                        this.saveFct();
                    }
                }
            ).finally(() => this.isLoading = false);
        },
        saveExistingWorkOrder() {
            this.isLoading = true;
            CalendarApiService.UpdateWorkOrder({
                workOrder: {
                    ...this.workorderinfo,
                    workOrderValues: this.$refs.workordervaluespanel.workOrderValues,
                    workOrderProgressValues: this.$refs.workordervaluespanel.workOrderStepValues
                },
                progressAppointmentsReviewList:
                    this.appointments.length == 0
                        ? null
                        : this.appointments,
            }).then(() => {
                this.trackEvent("UpdateWorkOrder");
                this.saveFct();
            }).finally(() => this.isLoading = false);
        },
        completeWorkOrder() {
            if (this.workorderinfo.idPublic == null) {
                this.saveNewWorkOrder();
            } else {
                this.saveExistingWorkOrder();
            }
        },
        makeToast(title, content, variant) {
            this.$bvToast.toast(content, {
                title: title,
                autoHideDelay: 5000,
                appendToast: false,
                variant: variant,
                solid: true,
            });
        },
        loadDataNew() {
            this.isLoading = true;
            this.$refs.workorderinfopanel.loadData().then(() => {
                this.isLoading = false;
            });
        },
        templateChanged(template, oldTemplate) {
            if (this.state === 'create' && (this.originalTemplateIdPublic != null || this.originalTemplateIdPublic != undefined) && this.originalTemplateIdPublic != template.idPublic) {
                this.$refs.resetAppointmentsModal.show(oldTemplate, oldTemplate.name).then(() => {
                    this.originalTemplateIdPublic = null;
                    this.beforeValidateValues = this.creationBeforeValidateValues;
                    this.beforeValidateInfo = this.creationBeforeValidateInfo;
                }, () => {
                    this.workorderinfo.template = oldTemplate;
                });
            }
        },
        loadDataWorkOrder(workOrderIdPublic, isCopy) {
            this.isLoading = true;
            var loadInitialDataPromise = this.$refs.workorderinfopanel.loadData();
            var loadWorkOrderPromise = CalendarApiService.getWorkOrderByIdPublicNoWorkOrderSteps(workOrderIdPublic).then((response) => {
                //set work order fields
                if (isCopy) {
                    this.workorderinfo.idPublic = null;
                    this.originalTemplateIdPublic = response.data.data.template?.idPublic;
                } else {
                    this.workorderinfo.idPublic = response.data.data.idPublic;
                    this.originalTemplateIdPublic = response.data.data.template?.idPublic;
                }
                if (isCopy) {
                    this.workorderinfo.name = response.data.data.name + "-copy";
                } else {
                    this.workorderinfo.name = response.data.data.name;
                }
                this.workorderinfo.template = response.data.data.template;
                this.workorderinfo.jobType = response.data.data.jobType;
                this.workorderinfo.workOrderGroup = response.data.data.workOrderGroup;
                this.workorderinfo.projectManager = response.data.data.projectManager;
                this.workorderinfo.roomName = response.data.data.roomName;
                this.workorderinfo.contractor = response.data.data.contractor;
                this.workorderinfo.clientName = response.data.data.clientName;
                this.workorderinfo.clientPhone = response.data.data.clientPhone;
                this.workorderinfo.clientAddress = response.data.data.clientAddress;
                this.workorderinfo.clientAddressLine2 =
                    response.data.data.clientAddressLine2;
                this.workorderinfo.clientCity = response.data.data.clientCity;
                this.workorderinfo.clientState = response.data.data.clientState;
                this.workorderinfo.clientZipCode = response.data.data.clientZipCode;
                this.workorderinfo.clientEmail = response.data.data.clientEmail;
                this.workorderinfo.productionDate =
                    response.data.data.productionDate == null
                        ? null
                        : this.$dayjs(response.data.data.productionDate).format(
                            "YYYY-MM-DDTHH:mm"
                        );
                this.workorderinfo.deliveryDate =
                    response.data.data.deliveryDate == null
                        ? null
                        : this.$dayjs(response.data.data.deliveryDate).format(
                            "YYYY-MM-DDTHH:mm"
                        );
                this.workorderinfo.deliveryEndDate =
                    response.data.data.deliveryEndDate == null
                        ? null
                        : this.$dayjs(response.data.data.deliveryEndDate).format(
                            "YYYY-MM-DDTHH:mm"
                        );
                this.workorderinfo.installationDate =
                    response.data.data.installationDate == null
                        ? null
                        : this.$dayjs(response.data.data.installationDate).format(
                            "YYYY-MM-DDTHH:mm"
                        );
                this.workorderinfo.installationEndDate =
                    response.data.data.installationEndDate == null
                        ? null
                        : this.$dayjs(response.data.data.installationEndDate).format(
                            "YYYY-MM-DDTHH:mm"
                        );
                this.workorderinfo.shipper = response.data.data.shipper;
                this.workorderinfo.installers = response.data.data.installers;
                this.workorderinfo.cadCamMerged = response.data.data.cadCamMerged;

                //Set values
                for (var value of this.workordervalues) {
                    //trouver si valeur existe
                    let woValueAssoc = response.data.data.workOrderValues.filter(
                        (woValue) => {
                            return woValue.measureType === value.measureType;
                        }
                    );
                    if (woValueAssoc.length > 0) {
                        woValueAssoc = woValueAssoc[0];
                        if (isCopy) {
                            value.idPublic = null;
                        } else {
                            value.idPublic = woValueAssoc.idPublic;
                        }
                        value.measureType = woValueAssoc.measureType;
                        value.value = woValueAssoc.value;
                    }
                }
            });
            Promise.all([loadInitialDataPromise, loadWorkOrderPromise]).then(() => {
                this.isLoading = false;
                this.$refs.workorderinfopanel.$v.$reset();
            });
        },
    },
};
</script>
<template>
    <form @submit.prevent="">
        <loading :active.sync="isLoading" :can-cancel="false" color="#f1b44c" :width="70" :height="70" loader="dots"
            :is-full-page="false">
        </loading>
        <div class="row">
            <div class="col-12">
                <form-wizard ref="wizard" color="#556ee6" :nextButtonText="$t('general.next')"
                    :backButtonText="$t('general.back')"
                    :finishButtonText="state === 'edit' ? $t('general.save') : $t('general.finish')"
                    @on-complete="completeWorkOrder">
                    <tab-content icon="fas fa-info" :before-change="beforeValidateInfo">
                        <WorkOrderDetailInfo ref="workorderinfopanel" v-model="workorderinfo"
                            @template-changed="templateChanged" />
                    </tab-content>
                    <tab-content icon="mdi mdi-table-large" :before-change="beforeValidateValues">
                        <WorkOrderDetailValues ref="workordervaluespanel" v-model="workordervalues" />
                    </tab-content>
                    <tab-content icon="fas fa-code-branch" :before-change="validateAppointments" v-if="editAppointments">
                        <WorkOrderDetailAppointments ref="appointmentspanel" v-model="appointments"
                            @revalidate="revalidateAppointments" />
                    </tab-content>
                    <template slot="custom-buttons-right" slot-scope="props">
                        <b-button @click="completeWorkOrder" class="ml-1 wizard-btn float-right"
                            :style="props.fillButtonStyle" v-if="!props.isLastStep && !editAppointments">{{
                                $t("general.save") }}</b-button>
                    </template>
                </form-wizard>
            </div>
        </div>
        <ResetAppointmentsModal ref="resetAppointmentsModal" :title="$t('general.resetAppointments')"
            :message="$t('general.areYouSureResetCopyAppointments')" />
    </form>
</template>