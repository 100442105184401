<script>
import router from "@/router";
import { layoutComputed, generalNotificationComputed } from "@/state/helpers";
import { layoutMethods, generalNotificationMethods } from "@/state/helpers";

import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import RightBar from "@/components/right-bar";
//import Footer from "@/components/footer";

/**
 * Vertical layout
 */
export default {
  components: {
    NavBar,
    SideBar,
    RightBar,
  },
  //Footer },
  data() {
    return {
      isMenuCondensed: window.innerWidth < 992 ? false : localStorage.getItem("menuCondensed") === 'true' ? true : false,
    };
  },
  props: {
    contentPadding: Boolean,
  },
  computed: {
    ...layoutComputed,
    ...generalNotificationComputed,
  },
  created: function () {

    //window.console.log("PADDING: " + this.contentPadding);

    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    ...layoutMethods,
    ...generalNotificationMethods,
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.innerWidth >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
      localStorage.setItem("menuCondensed", this.isMenuCondensed);
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper" style="min-height: 100vh; display: flex; flex-direction: column">
      <NavBar />
      <SideBar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <div class="main-content" style="display: flex; flex-direction: column; min-height: 100vh">
        <div class="d-flex page-content-no-padding" v-if="hasGeneralNotification">
          <b-alert show dismissible @dismissed="setGeneralNotificationDismissed" :variant="generalNotificationVariant"
            class="d-flex w-100 justify-content-center mb-0">{{ generalNotificationMessage
            }}</b-alert>
        </div>
        <div style="display: flex; flex: 1 1 0" class="page-content" v-bind:class="{
          'page-content': contentPadding,
          'page-content-no-padding': !contentPadding && !hasGeneralNotification,
          'page-content-no-padding-notification': !contentPadding && hasGeneralNotification,
        }">
          <!-- Start Content-->
          <div style="display: flex; flex-direction: column; flex: 1 1 0" class="container-fluid"
            v-bind:class="{ 'p-0': !contentPadding }">
            <slot />
          </div>
        </div>
        <!-- <Footer /> -->
      </div>
      <RightBar>
        <slot name="page_configuration_menu" />
      </RightBar>
    </div>
  </div>
</template>
