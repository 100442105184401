<script>
import {
    layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import WorkOrderDetailModal from "@/components/workorder-detail/workorder-detail-modal";
import UserConfigMixin from "@/mixins/userConfigMixin";

export default {
    mixins: [UserConfigMixin],
    components: {
        Vertical,
        Horizontal,
        WorkOrderDetailModal,
    },
    props: {
        contentPadding: {
            type: Boolean,
            default: true
        }
    },
    created: function () {
        //window.console.log("LAYOUT PADDING: " + this.contentPadding);
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
};
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical'" :layout="layoutType" :contentPadding="contentPadding">
            <template v-slot:page_configuration_menu>
                <slot name="page_configuration_menu" />
            </template>
            <slot />
        </Vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot />
        </Horizontal>
        <WorkOrderDetailModal
            v-if="($route.name == 'default' || $route.name == 'workOrders') && userCanCreateWorkOrder" />
    </div>
</template>
