import Vue from 'vue'
import Store from '@/state/store'

export function userHasPermissions(userPermissions, permissionsRequiredList) {
  if(permissionsRequiredList == null || permissionsRequiredList.length == 0)
    return true;

  if(userPermissions == null || userPermissions.length == 0)
    return false;

  let hasPermissions = true;

  for(var permissionRequired of permissionsRequiredList){
    hasPermissions = userPermissions[permissionRequired];
    if(hasPermissions == false){
      break;
    }

  }

  return hasPermissions;
}

var UserConfigMixin = {
  data () {
    return {

    }
  },
  methods: {
    userHasOptionNeeded(menuItem){
      if(typeof menuItem?.meta?.keyOption == "undefined" || menuItem?.meta?.keyOption == null){
        return true;
      }
      else
      {
        return this.userConfig.hasSchedulingOption;
      }
    },
    userCanAccessRoute(menuItem){
      if(menuItem.meta?.rolesRequired == null)
        return true;

      let hasRoles = true;

      let userRole = this.userConfig.planificationRole;
      let rolesRequiredList = menuItem.meta.rolesRequired.split(",");
      //let rolesRequiredList = ["admin"];
      for(let roleRequired of rolesRequiredList){
        let userHasRole = userRole == roleRequired;
        if(!userHasRole){
          hasRoles = false
          break;
        }
      }

      return hasRoles;
    },
    userHasPermissions,
    userHasPermissionsForMenuItem(menuItem){
      let userPermissions = this.userConfig.permissions;
      let permissionsRequiredList = menuItem.meta?.permissionsRequired?.split(",");  
      
      return userHasPermissions(userPermissions, permissionsRequiredList)
    },
    userCanSeeProgressInWorkOrderInfoSection(progress){
      let canSee = false;

      let progressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id;
      });

      if(progressConfig.length > 0){
        progressConfig = progressConfig[0];
        canSee = progressConfig.displayInWorkOrderInfoSection;
      }

      return canSee;
    },  
    userCanSeeProgressInLegend(progress)
    {
      let canSee = false;

      let progressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id;
      });

      if(progressConfig.length > 0){
        progressConfig = progressConfig[0];
        canSee = progressConfig.displayInLegend;
      }

      return canSee;
    },
    userCanSeeAnyProgress()
    {
      let visibleProgress = Store.state.calendar.userConfig.progressVisibility.filter((pv) => {
        return pv.display == true;
      });

      return visibleProgress.length > 0
    }, 
    userCanSeeProgress(progress){
      let canSee = false;

      let progressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id;
      });

      if(progressConfig.length > 0){
        progressConfig = progressConfig[0];
        canSee = progressConfig.display;
      }

      return canSee;
    },
    userCanSeeProgressId(progressId){
      let canSee = false;

      let progressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.stepIdPublic == progressId;
      });

      if(progressConfig.length > 0){
        progressConfig = progressConfig[0];
        canSee = progressConfig.display;
      }

      return canSee;
    },
    userCanSeeAnyProgressInLegend()
    {
      let visibleProgressInLegend = this.userConfig.progressVisibility.filter((pv) => {
        return pv.displayInLegend == true;
      });

      return visibleProgressInLegend.length > 0
    },
    userCanSeeAnyShipper()
    {
      let visibleShippers = this.userConfig.shippersVisibility.filter((sv) => {
        return sv.display == true;
      });

      return visibleShippers.length > 0
    },
    userCanSeeShipper(shipper){
      let canSee = false;

      let shipperConfig;
      if(shipper.id == 0){
        shipperConfig = this.userConfig.shippersVisibility.filter((sv) => {
          return sv.shipper == null;
        });
      }else{
        shipperConfig = this.userConfig.shippersVisibility.filter((sv) => {
          return sv.shipper?.idPublic == shipper?.idPublic;
        });
      }

      if(shipperConfig.length > 0){
        shipperConfig = shipperConfig[0];
        canSee = shipperConfig.display;
      }

      return canSee;
    }, 
    userCanSeeShipperId(shipperId){
      let canSee = false;

      let shipperConfig;
      if(shipperId === 'DeliveryEvent.00000000-0000-0000-0000-000000000000'){
        shipperConfig = this.userConfig.shippersVisibility.filter((sv) => {
          return sv.shipper == null;
        });
      }else{
        shipperConfig = this.userConfig.shippersVisibility.filter((sv) => {
          return sv.shipper?.idPublic == shipperId;
        });
      }

      if(shipperConfig.length > 0){
        shipperConfig = shipperConfig[0];
        canSee = shipperConfig.display;
      }

      return canSee;
    }, 
    userCanSeeAnyInstaller()
    {
      let visibleInstallers = Store.state.calendar.userConfig.installersVisibility.filter((iv) => {
        return iv.display == true;
      });

      return visibleInstallers.length > 0
    },
    userCanSeeInstaller(installer){
      let canSee = false;

      let installerConfig;
      if(installer.id == 0){
        installerConfig = this.userConfig.installersVisibility.filter((iv) => {
          return iv.installer == null;
        });
      }else{
        installerConfig = this.userConfig.installersVisibility.filter((iv) => {
          return iv.installer?.idPublic == installer?.idPublic;
        });
      }

      if(installerConfig.length > 0) {
        installerConfig = installerConfig[0];
        canSee = installerConfig.display;
      }

      return canSee;
    }, 
    userCanSeeInstallerId(installerId){
      let canSee = false;

      let installerConfig;
      if(installerId === 'InstallationEvent.00000000-0000-0000-0000-000000000000'){
        installerConfig = this.userConfig.installersVisibility.filter((iv) => {
          return iv.installer == null;
        });
      }else{
        installerConfig = this.userConfig.installersVisibility.filter((iv) => {
          return iv.installer?.idPublic == installerId;
        });
      }

      if(installerConfig.length > 0) {
        installerConfig = installerConfig[0];
        canSee = installerConfig.display;
      }

      return canSee;
    }, 
    userCanSeeProduction(){
      let canSee = false;

      let userProductionConfig = this.userConfig.productionVisibility;
      canSee = userProductionConfig.display;

      return canSee;
    },      
    userCanSeeProgressCapacities(progress){
      let canSee = false;

      if(progress == null || typeof progress == "undefined"){

        return false;
      }

      let userProgressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id
      })

      if(userProgressConfig.length > 0){
        userProgressConfig = userProgressConfig[0];

        canSee = userProgressConfig.displayCapacities;
      }

      return canSee;
    },
    userCanSeeDamaged(progress) {
      let canSee = false;

      if(progress == null || typeof progress == "undefined"){

        return false;
      }

      let userProgressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id
      })

      if(userProgressConfig.length > 0){
        userProgressConfig = userProgressConfig[0];

        canSee = userProgressConfig.displayDamaged;
      }

      return canSee;
    },
    userCanSeeBackOrder(progress) {
      let canSee = false;

      if(progress == null || typeof progress == "undefined"){

        return false;
      }

      let userProgressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id
      })

      if(userProgressConfig.length > 0){
        userProgressConfig = userProgressConfig[0];

        canSee = userProgressConfig.displayBackOrder;
      }

      return canSee;
    },
    userCanEditProgress(progress){
      let canEdit = false;

      let userProgressConfig = this.userConfig.progressVisibility.filter((pv) => {
        return pv.progress.id == progress.id
      })

      if(userProgressConfig.length > 0){
        userProgressConfig = userProgressConfig[0];
        canEdit = userProgressConfig.editor
      }

      return canEdit;
    },
    userCanEditProgressEvent(event){
      let stepIds = event.extendedProps == undefined ? JSON.parse(event.stepIds.toLowerCase().replaceAll("'", '"')) : event.extendedProps.stepIds;
      return this.userConfig.progressVisibility.find((pv) => stepIds.includes(pv.progress.stepIdPublic) && pv.editor === true) !== undefined;
    },
    userCanEditInstaller(installer){
      let canEdit = false;

      let userInstallerConfig = this.userConfig.installersVisibility.filter((iv) => {
        return iv.installer?.idPublic == installer.idPublic;
      })

      if(userInstallerConfig.length > 0){
        userInstallerConfig = userInstallerConfig[0];
        if(userInstallerConfig.editor){
          canEdit = true;
        }
      }      

      return canEdit;
    },
    userCanEditInstallationEvent(event){
      let canEdit = false;

      if(event.extendedProps.installerIds != null && event.extendedProps.installerIds.length > 0){
        //traverser les workorder installers et verifier si il y en a un que le user peut edit
        let workOrderInstallersIdPublic = event.extendedProps.installerIds
        
        for(let installerIdPublic of workOrderInstallersIdPublic){
          let userInstallerConfig = this.userConfig.installersVisibility.filter((iv) => {
            return iv.installer?.idPublic == installerIdPublic;
          })

          if(userInstallerConfig.length > 0){
            userInstallerConfig = userInstallerConfig[0];
            if(userInstallerConfig.editor){
              canEdit = true;
              break;
            }
          }
        }
      }else{
        //can edit unassigned installers
        let unassignedInstallerConfig = this.userConfig.installersVisibility.filter((iv) => {
          return iv.installer == null;
        })[0];
        
        canEdit = unassignedInstallerConfig.editor;
      }

      return canEdit;
    },
    userCanEditDeliveryEvent(event){
      let canEdit = false;

      if(event.extendedProps.shipper != null){
        let userShipperConfig = this.userConfig.shippersVisibility.filter((dv) => {
          return dv.shipper?.idPublic == event.extendedProps.shipper?.idPublic
        })
  
        if(userShipperConfig.length > 0){
          userShipperConfig = userShipperConfig[0];
          canEdit = userShipperConfig.editor
        }
      }else{
        //can edit unassigned shipper
        let unassignedShipperConfig = this.userConfig.shippersVisibility.filter((dv) => {
          return dv.shipper == null;
        })[0];
        
        canEdit = unassignedShipperConfig.editor;
      }

      return canEdit;
    },
    userCanEditProductionEvent(){
      let canEdit = false;
      let productionConfig = this.userConfig.productionVisibility;
      if(productionConfig != null){
        canEdit = productionConfig.editor;
      }

      return canEdit;
    },
    userCanEditEvent(event){
      let canEdit = false;

      let eventType = event.eventType == undefined ? event.extendedProps.eventType : event.eventType;
      if(eventType == "UserEvent"){
        //user event;
        canEdit = true;

      }else if(eventType == "StepEvent"){

        canEdit = this.userCanEditProgressEvent(event);

      }else if(eventType == "InstallationEvent"){

        canEdit = this.userCanEditInstallationEvent(event);

      }else if(eventType == "DeliveryEvent"){

        canEdit = this.userCanEditDeliveryEvent(event);

      }else if(eventType == "ProductionEvent"){

        canEdit = this.userCanEditProductionEvent(event);

      }

      return canEdit;
    },
    userHasSchedulingOption(){

    }
  },
  computed: {
    isTaskManagementOrganisationUser() {
      return window.static_web_app_config.mngm_organisations.includes(Vue.prototype.$msal.currentAccount.idTokenClaims.extension_OrganisationCode);
    },
    userDocumentationFolders() {
      let folders = [];
      if(this.userCanSeeDocumentationPlansAndDrawings){
        folders.push({name: this.$t('general.plansAndDrawings'), path: 'plans', icon: 'mdi-text-box-multiple-outline' })
      }
      if(this.userCanSeeDocumentationContracts) {
        folders.push({name: this.$t('general.contracts'), path: 'contracts', alternate_path: 'contrats', icon: 'mdi-handshake' })
      }
      if(this.userCanSeeDocumentationBills) {
        folders.push({name: this.$t('general.bills'), path: 'bills', icon: 'mdi-file-table-outline' })
      }
      if(this.userCanSeeDocumentationPurchaseOrders) {
        folders.push({name: this.$t('general.purchaseOrder'), path: 'purchaseOrder', alternate_path: 'bons' , icon: 'mdi-cart-outline' })
      }
      if(this.userCanSeeDocumentationQuotes) {
        folders.push({name: this.$t('general.quotes'), path: 'quotes', alternate_path: 'soum', icon: 'mdi-clipboard-list-outline' })
      }
      if(this.userCanSeeDocumentationOthers) {
        folders.push({name: this.$t('general.otherDocuments'), path: 'others', alternate_path: 'autres', icon: 'mdi-dots-horizontal-circle-outline' })
      }
      return folders;
    },
    userCanCreateWorkOrder: {
      get(){
        return this.userConfig.permissions.createWorkOrder;
      },
      set(value){
        this.userConfig.permissions.createWorkOrder = value;
      }
    },
    userCanDeleteWorkOrder: {
      get(){
        return this.userConfig.permissions.deleteWorkOrder;
      },
      set(value){
        this.userConfig.permissions.deleteWorkOrder = value;
      }
    },
    userIsAdmin(){
      return this.userConfig.planificationRole.toLowerCase() == "admin";
    },
    userConfig() {
      return Store.state.calendar.userConfig
    },
    userCanSeeDocumentationPlansAndDrawings(){
      return this.userConfig.documentationPlansAndDrawingsVisible;
    },
    userCanSeeDocumentationBills(){
      return this.userConfig.documentationBillsVisible;
    },
    userCanSeeDocumentationContracts(){
      return this.userConfig.documentationContractsVisible;
    },
    userCanSeeDocumentationOthers(){
      return this.userConfig.documentationOthersVisible;
    },
    userCanSeeDocumentationPurchaseOrders(){
      return this.userConfig.documentationPurchaseOrdersVisible;
    },
    userCanSeeDocumentationQuotes(){
      return this.userConfig.documentationQuotesVisible;
    },
    userCanSeeAnyDocumentation(){
      return this.userCanSeeDocumentationPlansAndDrawings || this.userCanSeeDocumentationBills || this.userCanSeeDocumentationContracts || this.userCanSeeDocumentationOthers || this.userCanSeeDocumentationPurchaseOrders || this.userCanSeeDocumentationQuotes
    }                      
  }
}

export default UserConfigMixin