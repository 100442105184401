<script>
import DatePicker from "vue2-datepicker";
import CalendarApiService from "@/services/calendarService";
import { Sketch } from "vue-color";
import UserConfigMixin from "@/mixins/userConfigMixin";
import CompleteStepsBeforeModal from "@/router/views/modals/completeStepsBeforeModal";
import UsersMultiSelect from "@/components/users-multi-select.vue";
import ConfirmModal from "../confirm-modal.vue";

export default {
  mixins: [UserConfigMixin],
  components: {
    DatePicker,
    Sketch,
    CompleteStepsBeforeModal,
    UsersMultiSelect,
    ConfirmModal
  },
  data() {
    return {
      showModal: false,
      editEvent: this.getEmptyEditEventModel(),
      eventInfo: null,
      categories: [],
      itemsList: [],
      usersList: [],
      oldStartDate: null,
      openValues: false,
      showColorPicker: false,
      isLoading: false,
      isLoadingInitial: false,
      completePreviousStepsOnComplete: true,
      duplicate: false,
      emailLoading: false,
      resolveFunction: null,
      rejectFunction: null,
    };
  },
  created() {},
  beforeDestroy() {},
  watch: {},
  computed: {
    documentation_event() {
      return {
        id: this.editEvent.userCreated
          ? this.editEvent.id
          : this.editEvent.title,
        title: this.editEvent.title,
        userCreated: this.editEvent.userCreated,
      };
    },
    documentation_options() {
      return {
        folders: this.userDocumentationFolders
      };
    },
    isLoadingOverlay() {
      return this.isLoading || this.isLoadingInitial;
    },
    statusList() {
      return this.$store.state.calendar.appointmentStatus;
    },
    googleMapLink() {
      return "http://maps.google.com/?q=" + this.clientAddress;
    },
    clientAddressAndPhone() {
      let phone = this.editEvent.workOrder?.clientPhone;
      phone != null;
      return this.clientAddress + (phone != null ? phone : "");
    },
    clientAddress() {
      let address = this.editEvent.workOrder?.clientAddress;
      let address2 = this.editEvent.workOrder?.clientAddressLine2;
      let city = this.editEvent.workOrder?.clientCity;
      let state = this.editEvent.workOrder?.clientState;
      let zipCode = this.editEvent.workOrder?.clientZipCode;

      return (
        (address != null ? address + ", " : "") +
        (address2 != null ? address2 + ", " : "") +
        (city != null ? city + ", " : "") +
        (state != null ? state + ", " : "") +
        (zipCode != null ? zipCode + ", " : "")
      );
    },
    startDate() {
      return this.editEvent.startDate;
    },
    itemLabel() {
      let label = "";
      switch (this.editEvent.type) {
        case "DeliveryEvent":
          label = "Shipper";
          break;
        case "InstallationEvent":
          label = "Installer";
          break;
      }

      return label;
    },
    isStarted() {
      return this.editEvent.status == "Started";
    },
    isCompleted() {
      return this.editEvent.status == "Completed";
    },
    isPending() {
      return this.editEvent.status == "Pending";
    },
    resourceHasEmail() {
      if (this.editEvent.type == "installation") {
        let found = this.editEvent.installers?.find((el) =>
          this.itemsList.find((x) => x.idPublic == el.idPublic && x.hasEmails)
        );
        if (found) {
          return true;
        }
      }
      if (this.editEvent.type == "delivery") {
        let shipper =
          this.editEvent.shipper ?? this.editEvent.workOrder.shipper;
        return !shipper
          ? null
          : this.itemsList.find((x) => x.idPublic === shipper.idPublic)
              ?.hasEmails ?? false;
      }
      return false;
    },
  },
  methods: {
    openEditEvent(eventId, data, code) {
      return new Promise(async (resolve, reject) => {
        this.resolveFunction = resolve;
        this.rejectFunction = reject;
        this.eventInfo = data.eventInfo;
        if(code) {
          this.duplicate = true; this.isMainEvent = false;
        } else {
          this.duplicate = false;
        }

        this.openValues = false;

        this.isLoadingInitial = true;
        await this.loadItemsDropdown(
          data.eventInfo.event._def.extendedProps.eventType
        );
        this.setEventModel(data.eventInfo.event);

        this.showModal = true;
      });
    },
    async openEditEventDialog(data, code) {
      this.eventInfo = data.eventInfo;
      code ? (this.duplicate = true) : (this.duplicate = false);

      this.openValues = false;

      this.isLoadingInitial = true;
      await this.loadItemsDropdown(
        data.eventInfo.event._def.extendedProps.eventType
      );
      this.setEventModel(data.eventInfo.event);

      this.showModal = true;
    },
    setMissingValues() {
      for (let measureType of this.measureTypes) {
        let exist = this.editEvent.values.filter((values) => {
          return values.measureType == measureType;
        });

        //Si la capacite nest pas dans la liste, lajouter pour permettre de la creer pour le work order
        if (exist.length == 0) {
          let model = {
            id: null,
            idPublic: null,
            measureType: measureType,
            value: null,
          };
          this.editEvent.values.push(model);
        }
      }
    },
    changeStartDate(newStartDate) {
      // move end date
      let diff = this.$dayjs(newStartDate).diff(this.oldStartDate);
      this.editEvent.endDate = this.$dayjs(this.editEvent.endDate)
        .add(diff, "millisecond")
        .format("YYYY-MM-DD");
    },
    async loadItemsDropdown(eventType) {
      let response = null;
      switch (eventType) {
        case "DeliveryEvent":
          response = await CalendarApiService.getShippers();
          break;
        case "InstallationEvent":
          response = await CalendarApiService.getInstallers();
          break;
      }
      this.itemsList = response?.data.data;
    },
    getEmptyEditEventModel() {
      return {
        id: null,
        title: null,
        eventType: null,
        isMainEvent: null,
        allDay: true,
        status: {
          id: null,
          name: null,
        },
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        shipper: null,
        installers: null,
        progress: null,
        comment: null,
        users: null,
        values: [],
        workOrder: {},
        userCreated: null,
        customHexColor: "",
      };
    },
    closeModal() {
      this.showModal = false;
      this.duplicate = false;

      //empty lorsque le dialog nest plus visible
      setTimeout(() => {
        this.openValues = false;
        this.editEvent = this.getEmptyEditEventModel();
      }, 500);
    },
    async saveEvent() {
      this.editEvent.startDate = this.$dayjs(this.editEvent.startDate).format(
        "YYYY-MM-DD"
      );
      this.editEvent.endDate = this.$dayjs(this.editEvent.endDate).format(
        "YYYY-MM-DD"
      );

      if (this.duplicate) {
        this.duplicateEvent(this.eventInfo, this.editEvent);
      } else {
        await this.updateEventDetails(this.eventInfo, this.editEvent);
      }
    },
    async setEventModel(event) {
      //Type(progress, installation, delivery, production)
      this.editEvent.eventType = event.extendedProps.eventType;
      //load workOrder
      if (event.extendedProps.userCreated == false) {
        let workOrderResponse = await CalendarApiService.getWorkOrderByIdPublicNoWorkOrderSteps(
          event.extendedProps.workOrderIdPublic
        );
        this.editEvent.workOrder = workOrderResponse.data.data;

        if (this.editEvent.eventType == "StepEvent") {
          //load work order step values
          let workOrderStepValues = await CalendarApiService.getWorkOrderStepValues(
            event.extendedProps.workOrderStepIdPublic
          );
          this.editEvent.values = workOrderStepValues.data.data;
          this.setMissingValues();

          this.editEvent.status = event.extendedProps.status;
          this.editEvent.progress = this.$store.state.calendar.progress.find(x => event.extendedProps.stepIds.includes(x.stepIdPublic));
        }
      }

      this.editEvent.id = event._def.publicId;
      this.editEvent.title = event.title;
      this.editEvent.userCreated = event.extendedProps.userCreated;

      this.editEvent.customHexColor =
        event.extendedProps.customHexColor == null
          ? ""
          : event.extendedProps.customHexColor;

      this.editEvent.allDay = event.allDay;
      this.editEvent.isMainEvent = event.extendedProps.isMainEvent ?? false;
      this.editEvent.installers =
        this.editEvent.isMainEvent == false
          ? this.itemsList?.filter(
              (item) =>
                event.extendedProps.installerIds?.includes(item.idPublic) ?? []
            ) ?? []
          : this.editEvent.workOrder.installers;
      this.editEvent.startDate = this.$dayjs(event.start).format("YYYY-MM-DD");
      this.editEvent.startTime = this.$dayjs(event.start).format("HH:mm");

      if (event.end) {
        this.editEvent.endDate = this.$dayjs(event.end).format("YYYY-MM-DD");
        this.editEvent.endTime = this.$dayjs(event.end).format("HH:mm");
      } else {
        this.editEvent.endDate = this.$dayjs(event.start).format("YYYY-MM-DD");
        this.editEvent.endTime = null;
      }

      this.editEvent.comment = event.extendedProps.comment;

      this.editEvent.users = event.extendedProps.stepUserIds ?? []

      this.isLoadingInitial = false;
    },
    startEvent() {
      let startedStatus = this.statusList.filter(function(status) {
        return status == "Started";
      })[0];

      this.editEvent.status = startedStatus;
    },
    confirmBeforeComplete() {
      this.$refs.confirmModal.show().then((response) => {
        if (response == true) {
          this.completeEvent();
        }
      })
    },
    completeEvent() {
      //get progress config
      this.isLoading = true;
      let ev = this.editEvent;
      CalendarApiService.getProgressConfig(this.editEvent.progress.id).then(
        (response) => {
          this.isLoading = false;
          let config = response.data.data;
          if (
            config.askToCompletePreviousProgress == true &&
            ev.progress.id != ev.workOrder.nextProgressToComplete.id
          ) {
            this.$refs.completeStepsBeforeModal.show().then(
              (userResponse) => {
                this.completePreviousStepsOnComplete = userResponse;
                this.setEventComplete();
              },
              () => {}
            );
          } else {
            this.completePreviousStepsOnComplete = true;
            this.setEventComplete();
          }
        }
      );
    },
    setEventComplete() {
      let completedStatus = this.statusList.filter(function(status) {
        return status == "Completed";
      })[0];

      this.editEvent.status = completedStatus;

      //set start date to today
      var today = this.$dayjs().format("YYYY-MM-DD");

      var diff = this.$dayjs(this.editEvent.endDate).diff(
        this.$dayjs(this.editEvent.startDate),
        "day"
      );
      this.editEvent.startDate = today;
      this.editEvent.endDate = this.$dayjs(this.editEvent.startDate)
        .add(diff, "day")
        .format("YYYY-MM-DD");
    },
    toggleValues() {
      this.openValues = !this.openValues;
    },
    changeAllDay() {
      if (this.editEvent.allDay) {
        //update end time
        let newEndDate = this.$dayjs(this.editEvent.startDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
        this.editEvent.endDate = newEndDate;
        this.editEvent.endTime = null;
      }
    },
    updateColorProgress(color) {
      this.editEvent.customHexColor = color.hex;
    },
    toggleColorPicker() {
      this.showColorPicker = !this.showColorPicker;
    },
    getStartDateLabel() {
      let label = "";

      switch (this.editEvent.eventType) {
        case "StepEvent":
          label = this.$t("general.startDate");
          break;
        case "UserEvent":
          label = this.$t("general.startDate");
          break;
        case "DeliveryEvent":
          label = this.$t("general.deliveryDate");
          break;
        case "InstallationEvent":
          label = this.$t("general.installationDate");
          break;
        case "ProductionEvent":
          label = this.$t("general.productionDate");
          break;
      }

      return label;
    },
    async updateEventDetails(eventInfo, eventForm) {
      let userCreated = eventInfo.event.extendedProps.userCreated;

      if (userCreated) {
        this.updateUserAppointment(eventInfo, eventForm);
      } else if (
        eventForm.eventType == "InstallationEvent" ||
        eventForm.eventType == "DeliveryEvent" ||
        eventForm.eventType == "ProductionEvent"
      ) {
        this.updateReferenceAppointment(eventInfo, eventForm);
      } else if (eventForm.eventType == "StepEvent") {
        await this.updateProgressAppointment(eventInfo, eventForm);
      }
    },
    duplicateEvent(eventInfo, eventForm) {
      let model = this.getAppointmentDtoModel(eventForm, eventInfo.event);
      model.type = eventInfo.event.extendedProps.eventType.replace("Event", "");
      this.isLoading = true;

      CalendarApiService.duplicateInstallationAppointmentDetails(model).then(
        () => {
          this.isLoading = false;
          this.closeModal();
          this.resolveFunction();
        }
      );
    },
    updateUserAppointment(eventInfo, eventForm) {
      let model = this.getAppointmentDtoModel(eventForm, eventInfo.event);
      this.isLoading = true;
      CalendarApiService.updateUserAppointmentDetails(model).then(() => {
        this.isLoading = false;
        this.closeModal();
        this.resolveFunction();
      });
    },
    async updateReferenceAppointment(eventInfo, eventForm) {
      let oldStart = eventInfo.event.start;
      let newStart = eventForm.startDate;
      let startDateChanged =
        this.$dayjs(oldStart).format("YYYY-MM-DD") !=
        this.$dayjs(newStart).format("YYYY-MM-DD");
      //Si update start date verifier si il existe des lien de progres brisés
      if (startDateChanged) {
        let borkenLinkResponse = await CalendarApiService.checkIfBrokenLink(
          eventInfo.event.extendedProps.workOrderIdPublic,
          eventForm.eventType
        );
        let brokenLinkExist = borkenLinkResponse.data.data;
        if (brokenLinkExist) {
          //fermer details modal avant douvrir lautre
          this.showModal = false;
          //show pop up demandant si on deplace les event avec un broken link
          this.$bus.$emit("open-confirm-move-broken-link-dialog", {
            callBack: (userChoice) => {
              if (userChoice != null) {
                this.showModal = true;
                this.updateReferenceAppointmentDetails(
                  eventInfo.event,
                  eventForm,
                  userChoice
                );
              }
            },
          });
        } else {
          //move reference appointment avec true ou false si on veut que les broken link suivent ou pas
          this.updateReferenceAppointmentDetails(
            eventInfo.event,
            eventForm,
            true
          );
        }
      } else {
        this.updateReferenceAppointmentDetails(
          eventInfo.event,
          eventForm,
          true
        );
      }
    },
    async updateProgressAppointment(eventInfo, eventForm) {
      let oldStart = eventInfo.event.start;
      let newStart = eventForm.startDate;
      let startDateChanged =
        this.$dayjs(oldStart).format("YYYY-MM-DD") !=
        this.$dayjs(newStart).format("YYYY-MM-DD");

      this.isLoading = true;

      let isMoveLater = (
        await CalendarApiService.getIsMoveLaterProgress(
          eventInfo.event.extendedProps.stepIds[0]
        )
      ).data.data;
      if (
        startDateChanged &&
        isMoveLater == true &&
        eventForm.status != "Completed"
      ) {
        let workOrderIdPublic = eventInfo.event.extendedProps.workOrderIdPublic;
        let response = await CalendarApiService.getLaterProgressAppointmentsV1_1(
          workOrderIdPublic,
          [eventForm.progress.stepIdPublic]
        );
        let laterProgressEvents = response.data.data;
        if (laterProgressEvents.length == 0) {
          this.updateProgressAppointmentDetails(eventInfo.event, eventForm);
        } else {
          //fermer details modal avant douvrir lautre
          this.showModal = false;
          this.$bus.$emit("open-move-progress-events-dialog", {
            events: laterProgressEvents,
            callBack: (selectedEvents) => {
              if (selectedEvents != null) {
                this.showModal = true;
                this.updateProgressAppointmentDetails(
                  eventInfo.event,
                  eventForm,
                  selectedEvents
                );
              }
            },
          });
        }
      } else {
        this.updateProgressAppointmentDetails(eventInfo.event, eventForm);
      }
    },
    updateProgressAppointmentDetails(event, eventForm, selectedEvents = []) {
      let model = this.getAppointmentDtoModel(eventForm, event);

      model.workOrderStepIdPublic = event.extendedProps.workOrderStepIdPublic;
      model.progress = eventForm.progress;
      model.userIds = eventForm.users;
      model.breakLinkToReferenceDate = event.extendedProps.breakLinkToReferenceDate;
      model.appointmentsToMove = selectedEvents;
      
      this.isLoading = true;
      CalendarApiService.updateProgressAppointmentDetails(model).then(() => {
        this.isLoading = false;
        this.closeModal();
        this.resolveFunction();
      });
    },
    updateReferenceAppointmentDetails(event, eventForm, moveBrokenLink) {
      let model = this.getAppointmentDtoModel(eventForm, event);
      model.moveBrokenLink = moveBrokenLink;
      model.type = event.extendedProps.eventType.replace("Event", "");
      this.isLoading = true;

      CalendarApiService.updateReferenceAppointmentDetails(model).then(() => {
        this.isLoading = false;
        this.closeModal();
        this.resolveFunction();
      });
    },
    getAppointmentDtoModel(eventForm, event) {
      eventForm.values.map((v) => {
        v.value = Number.isNaN(parseFloat(v.value))
          ? null
          : parseFloat(v.value);
      });
      let model = {
        id: event._def.publicId,
        title: eventForm.title,
        allDay: eventForm.allDay,
        isMainEvent: event.extendedProps.isMainEvent,
        installers: eventForm.installers,
        status: eventForm.status,
        startDate: eventForm.startDate,
        startTime: eventForm.startTime,
        endDate: eventForm.endDate,
        endTime: eventForm.endTime,
        comment: eventForm.comment,
        userCreated: event.extendedProps.userCreated,
        workOrder: eventForm.workOrder,
        customHexColor: eventForm.customHexColor,
        completePreviousProgressOnComplete: this
          .completePreviousStepsOnComplete,
        values: eventForm.values.filter((v) => {
          return !(v.id == null && Number.isNaN(parseFloat(v.value)));
        }),
      };

      return model;
    },
    sendEmail() {
      this.emailLoading = true;
      let model = {
        id: this.editEvent.id,
        title: this.editEvent.title,
        comment: this.editEvent.comment,
        allDay: this.editEvent.allDay,
        startDate: this.editEvent.startDate,
        endDate: this.editEvent.endDate,
        customHexColor: this.editEvent.customHexColor,
        workOrderIdPublic: this.editEvent.workOrder.idPublic,
        isMainEvent: this.editEvent.isMainEvent,
      };
      if (this.editEvent.eventType == "DeliveryEvent") {
        CalendarApiService.sendDeliveryEmail(model)
          .then(() => {
            this.closeModal();
          })
          .finally(() => {
            this.emailLoading = false;
          });
      }
      if (this.editEvent.eventType == "InstallationEvent") {
        CalendarApiService.sendInstallationEmail(model)
          .then(() => {
            this.closeModal();
          })
          .finally(() => {
            this.emailLoading = false;
          });
      }
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="duplicate ? $t('general.duplicateEvent') : $t('general.editEvent')"
    title-class="text-black font-18"
    scrollable
    body-class="p-3"
    id="eventDetailModal"
  >
    <form @submit.prevent="">
      <div class="row">
        <div class="col-12" style="position: relative">
          <loading
            :active.sync="isLoadingOverlay"
            :can-cancel="false"
            color="#f1b44c"
            :width="70"
            :opacity="0.5"
            :height="70"
            loader="dots"
            :is-full-page="false"
          ></loading>
          <div v-show="isLoadingInitial == false" class="row">
            <div class="col-12">
              <b-form-group class="font-weight-medium">
                <h3 v-if="editEvent.userCreated == false">
                  {{ editEvent.title }}
                </h3>
                <label v-if="editEvent.userCreated == true">{{
                  $t("general.title")
                }}</label>
                <b-form-input
                  v-if="editEvent.userCreated == true"
                  v-model="editEvent.title"
                  :placeholder="$t('general.title')"
                >
                </b-form-input>
                <a
                  v-if="editEvent.userCreated == false"
                  :href="googleMapLink"
                  target="_blank"
                  class="text-primary"
                  style="cursor: pointer"
                  ><i
                    style="font-size: 1.2em"
                    class="mr-1 mdi mdi-google-maps"
                  />{{ clientAddress }}</a
                >
                <a
                  v-if="editEvent.userCreated == false"
                  :href="`tel:${editEvent.workOrder.clientPhone}`"
                  target="_blank"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  <i style="font-size: 1.2em" class="mr-1 mdi mdi-phone" />{{
                    editEvent.workOrder.clientPhone
                  }}
                </a>
                <div
                  id="workOrderInfo"
                  v-if="
                    editEvent.userCreated == false &&
                      editEvent.workOrder != null
                  "
                >
                  <label>{{ editEvent.workOrder.clientName }}</label>
                  <br />
                  <span>
                    <label v-if="editEvent.workOrder.template">{{
                      editEvent.workOrder.template.name
                    }}</label>
                    <label v-if="editEvent.workOrder.jobType"
                      >&nbsp;|&nbsp;{{
                        editEvent.workOrder.jobType.name
                      }}</label
                    >
                    <label v-if="editEvent.workOrder.workOrderGroup"
                      >&nbsp;|&nbsp;{{
                        editEvent.workOrder.workOrderGroup.name
                      }}</label
                    >
                  </span>
                  <div style="display: flex">
                    <p
                      v-if="
                        editEvent.workOrder.productionDate &&
                          userCanSeeProduction()
                      "
                      class="mb-1 mr-4"
                    >
                      {{ $t("general.productionDate") }}: <br /><span
                        class="text-muted"
                        >{{
                          $dayjs(editEvent.workOrder.productionDate).format("L")
                        }}</span
                      >&nbsp;&nbsp;
                    </p>

                    <p
                      v-if="
                        editEvent.workOrder.deliveryDate &&
                          userCanSeeAnyShipper()
                      "
                      class="mb-1 mr-4"
                    >
                      {{ $t("general.deliveryDate") }}: <br /><span
                        class="text-muted"
                        >{{
                          $dayjs(editEvent.workOrder.deliveryDate).format("L")
                        }}</span
                      >&nbsp;&nbsp;
                    </p>

                    <p
                      v-if="
                        editEvent.workOrder.installationDate &&
                          userCanSeeAnyInstaller()
                      "
                      class="mb-1"
                    >
                      {{ $t("general.installationDate") }}: <br /><span
                        class="text-muted"
                        >{{
                          $dayjs(editEvent.workOrder.installationDate).format(
                            "L"
                          )
                        }}</span
                      >
                    </p>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div
              v-if="
                editEvent.eventType == 'StepEvent' &&
                  editEvent.userCreated == false
              "
              class="col-12"
            >
              <div class="row">
                <div
                  class="col-12"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <h5>
                    {{ $t("general.progressValues") }}
                  </h5>
                  <i
                    @click="toggleValues()"
                    v-b-toggle.collapseValues
                    v-bind:class="{
                      'fas fa-angle-down': !openValues,
                      'fas fa-angle-up': openValues,
                    }"
                    style="font-size: 1.5em; cursor: pointer"
                  ></i>
                </div>
                <b-collapse class="col-12 mt-2" id="collapseValues">
                  <div class="row">
                    <div
                      class="col-6 col-md-4"
                      v-for="(progressValue, index) in editEvent.values"
                      :key="index"
                      id="collapseValues"
                      style="display: flex; align-items: flex-end"
                    >
                      <b-form-group style="position: relative">
                        <label>{{
                          $t("general." + progressValue.measureType)
                        }}</label>
                        <b-form-input
                          :disabled="
                            editEvent.workOrder.origin != 'Planification'
                          "
                          style="max-width: 120px"
                          type="number"
                          step="0.01"
                          v-model="progressValue.value"
                          placeholder="0.00"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <hr />
            </div>
            <h5
              class="col-12"
              v-if="
                editEvent.eventType === 'StepEvent' &&
                  editEvent.progress !== null &&
                  editEvent.userCreated === false
              "
            >
              {{ $t("general.progress") }}:
              <b-badge
                pill
                class="mb-2"
                :style="{ 'background-color': editEvent.progress.colorHexa }"
              >
                {{ editEvent.progress.name }}
              </b-badge>
            </h5>
            <div
              v-if="
                editEvent.eventType == 'StepEvent' &&
                  editEvent.userCreated == false
              "
              class="col-12"
            >
              <b-form-group label="Status" class="font-weight-medium">
                <b-button
                  :disabled="isStarted || isCompleted"
                  @click="startEvent()"
                  variant="primary"
                  >{{ $t("general.start") }}</b-button
                >
                <b-button
                  :disabled="isCompleted"
                  @click="confirmBeforeComplete()"
                  variant="success"
                  class="ml-2"
                  >{{ $t("general.complete") }}</b-button
                >
                <i
                  v-show="isCompleted"
                  style="
                    color: #34c38f;
                    font-size: 2.5em;
                    vertical-align: middle;
                  "
                  class="bx bx-check-circle ml-2"
                ></i>
                <i
                  v-show="isStarted"
                  style="
                    color: #556ee6;
                    font-size: 1.8em;
                    vertical-align: middle;
                  "
                  class="mdi mdi-clock-start ml-2"
                ></i>
              </b-form-group>
            </div>

            <b-form-group
              class="col-12"
              v-if="editEvent.userCreated == true"
              style="position: relative"
            >
              <label>{{ $t("general.color") }}</label>
              <div class="mb-2" style="display: flex; align-items: center">
                <div
                  class="mr-2"
                  :style="{ background: editEvent.customHexColor }"
                  style="width: 27px; height: 27px; border-radius: 5px"
                ></div>
                <b-button
                  @click="toggleColorPicker()"
                  v-show="!showColorPicker"
                  size="sm"
                  variant="primary"
                  >{{ $t("general.selectColor") }}</b-button
                >
                <b-button
                  @click="toggleColorPicker()"
                  v-show="showColorPicker"
                  size="sm"
                  variant="primary"
                  >{{ $t("general.close") }}</b-button
                >
              </div>
              <Sketch
                v-show="showColorPicker"
                :value="editEvent.customHexColor"
                @input="updateColorProgress"
              />
            </b-form-group>

            <div
              v-if="
                editEvent.eventType != 'ProductionEvent' ||
                  editEvent.userCreated == true
              "
              class="col-12"
            >
              <b-form-checkbox
                v-model="editEvent.allDay"
                @change="changeAllDay()"
                class="custom-checkbox mb-3 mt-2"
                >{{ $t("general.allDayEvent") }}</b-form-checkbox
              >
            </div>
            <div class="col-6">
              <b-form-group
                :label="getStartDateLabel()"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="editEvent.startDate"
                  @focus="oldStartDate = editEvent.startDate"
                  @change="changeStartDate"
                  :first-day-of-week="1"
                  :format="this.$dayjs.localeData().longDateFormat('L')"
                  value-type="YYYY-MM-DD"
                  lang="en"
                >
                </date-picker>
              </b-form-group>

              <b-form-group
                v-if="
                  !editEvent.allDay &&
                    (editEvent.eventType != 'ProductionEvent' ||
                      editEvent.userCreated == true)
                "
                :label="$t('general.startTime')"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="editEvent.startTime"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  placeholder="HH:mm"
                ></date-picker>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                v-if="
                  editEvent.eventType != 'ProductionEvent' ||
                    editEvent.userCreated == true
                "
                :label="$t('general.endDate')"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="editEvent.endDate"
                  :first-day-of-week="1"
                  :format="this.$dayjs.localeData().longDateFormat('L')"
                  value-type="YYYY-MM-DD"
                  lang="en"
                ></date-picker>
              </b-form-group>
              <b-form-group
                v-if="
                  !editEvent.allDay &&
                    (editEvent.eventType != 'ProductionEvent' ||
                      editEvent.userCreated == true)
                "
                :label="$t('general.endTime')"
                class="font-weight-medium"
              >
                <date-picker
                  v-model="editEvent.endTime"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  placeholder="HH:mm"
                ></date-picker>
              </b-form-group>
            </div>
          </div>

          <b-form-group
            v-if="
              editEvent.eventType == 'DeliveryEvent' &&
                editEvent.userCreated == false
            "
            class="font-weight-medium"
            :label="$t('general.shipper')"
          >
            <multiselect
              :placeholder="$t('general.shipper')"
              v-model="editEvent.workOrder.shipper"
              :options="itemsList"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
          </b-form-group>

          <b-form-group
            v-if="
              editEvent.eventType == 'InstallationEvent' &&
                editEvent.userCreated == false
            "
            class="font-weight-medium"
            :label="$t('general.installer')"
          >
            <multiselect
              v-if="editEvent.isMainEvent"
              :multiple="true"
              :placeholder="$t('general.installers')"
              v-model="editEvent.workOrder.installers"
              :options="itemsList"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
            <multiselect
              v-else
              :multiple="true"
              :placeholder="$t('general.installers')"
              v-model="editEvent.installers"
              :options="itemsList"
              :show-labels="false"
              label="name"
              track-by="id"
            ></multiselect>
          </b-form-group>

          <b-form-group
            class="font-weight-medium"
            v-if="
              editEvent.eventType == 'StepEvent' &&
                editEvent.progress != null &&
                editEvent.progress.users != null &&
                editEvent.progress.users.length > 0
            "
            :label="$t('general.users')"
          >
            <UsersMultiSelect v-model="editEvent.users" :users="editEvent.progress.users" trackBy="id" label="displayName" />

            <!-- {{ editEvent.progress.users }} -->
            <!-- {{ editEvent.users }} -->
            <!-- <multiselect
              v-model="editEvent.users"
              :options="usersList"
              multiple
              :placeholder="$t('general.assignedUsers')"
              label="text"
              track-by="value"
            >
            </multiselect> -->

          </b-form-group>
          <b-form-group
            class="font-weight-medium"
            :label="$t('general.comment')"
          >
            <textarea
              v-model="editEvent.comment"
              class="form-control"
              rows="3"
              :placeholder="$t('general.comment')"
            ></textarea>
          </b-form-group>
          <b-form-group
            v-if="editEvent.userCreated == false"
            class="font-weight-medium"
            :label="$t('general.workOrderComment')"
          >
            <textarea
              v-model="editEvent.workOrder.comment"
              class="form-control"
              rows="3"
              :placeholder="$t('general.workOrderComment')"
            ></textarea>
          </b-form-group>
          <documentation-component
            :options="documentation_options"
            v-model="documentation_event"
            v-show="userCanSeeAnyDocumentation"
          />
        </div>
      </div>
    </form>
    <template #modal-footer>
      <div class="d-flex flex-row w-100">
        <div v-if="resourceHasEmail">
          <div
            v-if="
              eventInfo.event._def.extendedProps.eventType ==
                'InstallationEvent' ||
                eventInfo.event._def.extendedProps.eventType == 'DeliveryEvent'
            "
          >
            <b-button
              @click="sendEmail"
              class="d-flex align-items-center"
              variant="success"
            >
              {{ $t("general.sendEmail") }}
              <span
                v-if="emailLoading"
                class="spinner-border spinner-border-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            </b-button>
          </div>
        </div>
        <div class="ml-auto">
          <b-button variant="light" @click="closeModal">
            {{ $t("general.cancel") }}
          </b-button>
          <b-button @click="saveEvent()" class="ml-1" variant="success">
            {{ $t("general.save") }}
          </b-button>
        </div>
      </div>
    </template>

    <CompleteStepsBeforeModal
      ref="completeStepsBeforeModal"
    ></CompleteStepsBeforeModal>
    <ConfirmModal ref="confirmModal"/>
  </b-modal>
</template>