import i18n from '@/i18n';
import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
    menuCondensed: (state) => state.menuCondensed
  })
}

export const generalNotificationComputed = {
  ...mapState('generalnotification', {
    hasGeneralNotification: (state) => {
      return state === null ? false : state.code === state.lastCodeDismissed ? false : i18n.locale === 'en' ? (state.messageEN !== null && state.messageEN !== '' && state.messageEN !== undefined) :
        i18n.locale === 'fr' ? (state.messageFR !== null && state.messageFR !== '' && state.messageFR !== undefined) : false;
    },
    generalNotificationMessage: (state) => {
      if(state === null) {
        return "";
      }
      if(i18n.locale === 'en') {
        return state.messageEN;
      }
      if(i18n.locale === 'fr') {
        return state.messageFR;
      }
      return "";
    },
    generalNotificationVariant: (state) => {
      return state.type;
    }
  })
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue', 'toggleMenuCondensed', 'setMenuCondensed'])

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const generalNotificationMethods = mapActions('generalnotification', ['setGeneralNotification', 'setGeneralNotificationDismissed'])
