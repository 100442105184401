<script>

export default {
  components: {

  },
  data() {
    return {
      showModal: false,
      response: null
    };
  },
  created(){

  },
  beforeDestroy() {
    
  },   
  computed: {
  },
  methods: {
    show() {
      this.showModal = true;
      return new Promise((resolve, reject) => {
        this.response = (val) => {
          if(val == null) {
            reject();
          } else {
            resolve(val);  
          }          
        };
      }).finally(() => {
        this.showModal = false;
      });
    }
  },
};
</script>

<template>
  <b-modal 
    v-model="showModal" 
    :title="$t('general.completePreviousSteps')" 
    style="position:relative"
    hide-header-close
    no-close-on-backdrop>       
    <p>{{$t('general.completePreviousStepsText')}}</p>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="success" class="float-right" @click="response(true)">
          {{$t('general.complete')}}
        </b-button>        
        <b-button zvariant="danger" class="mr-2 float-right" @click="response(false)">
          {{$t('general.doNotComplete')}}
        </b-button>
        <b-button variant="light" class="mr-2 float-right" @click="response(null)">
          {{$t('general.cancel')}}
        </b-button>     
      </div>
    </template>
  </b-modal>
</template>