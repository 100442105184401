export const state = {
    type: null,
    messageFR: null,
    messageEN: null,
    code: null,
    userId: null,
    lastCodeDismissed: null
};

export const mutations = {
    setGeneralNotification(state, params) {
        state.type = params.notification.type;
        state.messageFR = params.notification.messageFR;
        state.messageEN = params.notification.messageEN;
        state.code = params.notification.code;
        state.userId = params.userId;
        state.lastCodeDismissed = !params.userId ? null : localStorage.getItem(params.userId + '.generalNotificationCodeDismissed') ?? null
    },
    setGeneralNotificationDismissed(state) {
        localStorage.setItem(state.userId + '.generalNotificationCodeDismissed', state.code);
        state.lastCodeDismissed = state.code;
    }
};

export const actions = {
    setGeneralNotification({ commit }, params) {
        commit('setGeneralNotification', params);
    },
    setGeneralNotificationDismissed({ commit }) {
        commit('setGeneralNotificationDismissed');
    }
};
