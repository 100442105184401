var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t("general.progressEventsOverview"))+" ")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table",staticStyle:{"height":"1px"}},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("general.progress")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.days")))]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("general.startDate"))+" ")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("general.capacity"))+" ")]),_c('th',{staticStyle:{"width":"100px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("general.use"))+" ")])])]),(_vm.appointments.length != 0)?_c('tbody',_vm._l((_vm.$v.appointments.$each.$iter),function(appointment,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('div',{staticClass:"mr-3",staticStyle:{"display":"inline-block","vertical-align":"middle","width":"20px","height":"20px","border-radius":"100px"},style:({
                    background: appointment.$model.progress.colorHexa,
                  })}),_c('span',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(appointment.$model.progress.name))])]),_c('td',[_c('b-form-group',[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.appointments.$error && appointment.$error,
                    },staticStyle:{"width":"60px","height":"40px"},attrs:{"type":"number","placeholder":"0"},on:{"change":function($event){return _vm.$emit('revalidate')}},model:{value:(appointment.numberOfDaysDefault.$model),callback:function ($$v) {_vm.$set(appointment.numberOfDaysDefault, "$model", $$v)},expression:"appointment.numberOfDaysDefault.$model"}}),(_vm.$v.appointments.$error && appointment.$error)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.$t("general.requiredValue")))])]):_vm._e()],1)],1),_c('td',{staticStyle:{"vertical-align":"middle","text-align":"center"},style:([
                  _vm.$dayjs(appointment.$model.startDate).isBefore(_vm.$dayjs(), 'day')
                    ? { color: 'rgb(244, 106, 106)' }
                    : { color: '' } ])},[_c('b-form-group',{staticClass:"required",staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(_vm.$dayjs(appointment.$model.startDate).format("L"))+" "),(_vm.$v.appointments.$error && appointment.$error)?_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(_vm.$t("general.requiredValue")))])]):_vm._e()])],1),_c('td',{staticStyle:{"vertical-align":"middle","text-align":"center"}},[(
                    appointment.$model.isOverCapacityData.isOverCapacity ==
                      false
                  )?_c('i',{staticClass:"mdi mdi-check-circle-outline",staticStyle:{"font-size":"1.8em","color":"rgb(52, 195, 143)"},attrs:{"id":'tooltip-' + index}}):_vm._e(),(
                    appointment.$model.isOverCapacityData.isOverCapacity == true
                  )?_c('i',{staticClass:"mdi mdi-alert-circle-outline",staticStyle:{"font-size":"1.8em","color":"#f46a6a"},attrs:{"id":'tooltip-' + index}}):_vm._e(),_c('b-tooltip',{attrs:{"target":'tooltip-' + index,"triggers":"hover"}},[(
                      JSON.stringify(
                        appointment.$model.isOverCapacityData.capacityData
                      ) != '{}'
                    )?_c('ul',{staticStyle:{"margin-left":"0","list-style-type":"none","padding-left":"0","text-align":"left","margin-bottom":"0"}},_vm._l((appointment.$model
                        .isOverCapacityData.capacityData),function(capacityData,index){return _c('li',{key:index,staticStyle:{"margin-left":"0","margin-bottom":"5px"}},[_vm._v(" "+_vm._s(_vm.$t("general." + index))+": "+_vm._s(capacityData.totalValue)+" / "+_vm._s(capacityData.capacity)+" ")])}),0):_vm._e()])],1),_c('td',{staticStyle:{"text-align":"center","height":"100%"}},[_c('div',{staticStyle:{"height":"100%","width":"100%","display":"flex","flex-direction":"column","align-items":"center","justify-content":"center"}},[_c('b-form-checkbox',{staticClass:"custom-checkbox",model:{value:(appointment.$model.checked),callback:function ($$v) {_vm.$set(appointment.$model, "checked", $$v)},expression:"appointment.$model.checked"}})],1)])])}),0):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }