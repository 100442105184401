//import i18n from '../i18n';
import { PublicClientApplication } from '@azure/msal-browser';

class MsalService {
    constructor(options) {
        this.options = options;        
        this.msalInstance = new PublicClientApplication(options.msalConfig);
    }
}

const MsalPlugin = {
    install(Vue, options) {
        Vue.prototype.$msalService = new MsalService(options);
    }
}

export default MsalPlugin;