<script>
// eslint-disable-next-line no-unused-vars
import CalendarApiService from "@/services/calendarService";

export default {
  components: {
  },
  props: {
    value: {
      type: Object
    },
    placeholder: String
  },
  data(){
    return {
      workOrderGroups: [],
      isLoading: false,
      debounceTimeout: null,
    }
  },
  computed: {
    locale(){
      return this.value ? this.value : null
    },
    placeholderComputed(){
      return (this.placeholder == null || typeof this.placeholder == "undefined") ? this.$t('general.group') : this.placeholder
    }
  },
  watch :{
    value(newValue){
      if(newValue != null){
        var exist = this.workOrderGroups.filter((g) => {return g.idPublic == newValue.idPublic});
        if(exist.length == 0){
          this.workOrderGroups.push(newValue)
        }
      }
    }
  },  
  methods:{
    async updateWorkOrderGroup(newValue){
      
      var valueToAssign = newValue;

      //si la selection nest pas null
      if(newValue != null){
        //si selection est idPublic 0 cest que le group nexiste pas encore
        //le creer et assigner le groupe nouvellement cree
        if(newValue.idPublic == 0){
          var newGroupName = newValue.newGroupName
          this.isLoading = true;
          var newGroup = await CalendarApiService.createWorkOrderGroup(newGroupName)
          this.isLoading = false;
          if(newGroup.data != null){
            valueToAssign = newGroup.data.data

            //ajouter a la liste des groupes pour pouvoir lenlever par la suite
            this.workOrderGroups.push(valueToAssign)

          }
        }
      }

      this.$emit('input', valueToAssign)
    },
    initGroupsArray(queryString){
      this.workOrderGroups = [];
      if(queryString != null && queryString != ""){
        var createRow = {
          idPublic: 0,
          name: queryString + " " + this.$t('general.' + '(newGroup)'),
          newGroupName: queryString
        }

        this.workOrderGroups.unshift(createRow);
      }
    },
    
    // eslint-disable-next-line no-unused-vars
    async searchWorkOrderGroups(queryString){
      this.workOrderGroups = [];
      if(queryString == "" || queryString == null){
        return;
      } 

      //debounce input event
      if(window.LIT2 != null){
        clearTimeout(window.LIT2);
      }

      window.LIT2 = setTimeout(async () => {
        //searchWorkOrderGroup
        this.isLoading = true;
        var response = await CalendarApiService.getWorkOrderGroups(queryString);
        this.isLoading = false;
        var groups = response.data.data

        this.initGroupsArray(queryString);
        for(var group of groups){
          this.workOrderGroups.push(group);
        }
      }, 500);
    }
  }
};
</script>
<template>
  <div>
    <multiselect  
      :value="locale" 
      @input="updateWorkOrderGroup" 
      :options="workOrderGroups" 
      :options-limit="35"
      @search-change="searchWorkOrderGroups"
      :placeholder="placeholderComputed" 
      label="name" 
      :allow-empty="true"
      :loading="isLoading"
      track-by="idPublic"
      :show-labels="false" >
        <template slot="noOptions">{{$t('general.searchGroupPlaceholder')}}</template>
        <template slot="noResult">{{$t('general.searchGroupPlaceholder')}}</template>
      </multiselect>
  </div>
</template>