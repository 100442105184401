
<script>
export default {
    components: {},
    data() {
        return {
            showModal: false,
            close: null,
            confirm: null
        };
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        show() {
            return new Promise((resolve) => {
                this.confirm = () => {
                    this.showModal = false;
                    resolve();
                };
                this.showModal = true;
            });
        }
    },
    computed: {

    },
};
</script>
<template>
    <b-modal v-model="showModal" :title="$t('general.replacevalues')" title-class="text-black font-18" 
      centered
      :ok-title="this.$t('general.replace')"
      :cancel-title="this.$t('general.close')"
      @ok="confirm()"
      no-close-on-backdrop
      ok-variant="danger">
      <p>
        {{ $t('general.replaceValueConfirmation') }}
      </p>
    </b-modal>
</template>