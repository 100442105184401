<script>
export default {
  props: ["title", "message"],
  data() {
    return {
      isLoading: false,
      showModal: false,
      deleteFunction: null,
      closeFunction: null,
      entity: null,
      entityName: null,
    };
  },
  methods: {
    show(entity, entityName) {
      this.entity = entity;
      this.entityName = entityName;
      this.showModal = true;
      return new Promise((resolve, reject) => {
        this.deleteFunction = (entity) => {
          this.isLoading = true;
          resolve(entity);
        };
        this.closeFunction = () => {
          reject();
        };
      }).finally(() => {
        this.isLoading = false;
        this.showModal = false;
        this.entity = null;
      });
    },
    close() {},
  },
};
</script>
<template>
  <b-modal 
    v-model="showModal" 
    :title="title" 
    centered
    no-close-on-backdrop
  >
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    ></loading>
    <div class="d-block text-center">
      <i class='bx bx-x-circle bx-tada bx-lg' style='color:#ff0000; size: 140px;' ></i>
      <h3>{{ message }}</h3>
    </div>
    <div class="text-center">
      <span class="text-center font-italic card-title-desc" v-if="entityName">{{ entityName }}</span>
      <p class="card-title-desc mt-2">
        {{$t('general.noUndo')}}
      </p> 
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="danger"
          class="float-right"
          @click="deleteFunction(entity)"
        >
          {{ $t("general.reset") }}
        </b-button>
        <b-button class="mr-2 float-right" @click="closeFunction">
          {{ $t("general.cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>