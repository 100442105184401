export const state = {
  workOrderDetailConfig: null,
};

const defaultConfigs = {
  workOrderDetailConfig: {
    productionDateIsOpen: false,
    clientInfoIsOpen: false,
    deliveryIsOpen: false,
    installationIsOpen: false
  },
};

export const mutations = {
  mutateByCode(state, { code, config }) {
    state[code] = config;
    localStorage.setItem(code, JSON.stringify(config));
  },
};

export const actions = {
  setByCode({ commit }, payload) {
    commit('mutateByCode', payload);
  }
};

export const getters = {
  getByCode: (state) => (configCode) => {
    let defaultValues = defaultConfigs[configCode];
    let savedValues = state[configCode];

    if (savedValues == null) {
      let localStorageConfig = localStorage.getItem(configCode);
      if (localStorageConfig != null) {
        savedValues = JSON.parse(localStorageConfig);
      }
    }

    return { ...defaultValues, ...savedValues };
  }
};
