import Vue from 'vue';
import EventsREST from './customREST/events';

//If you add a custom repository in this array it will be dynamically added to the repository custom options when get: is called.
const customRESTrepositories = {
  events: EventsREST
};

//If you add a custom repository in this array it will be dynamically added to the repository custom options when get: is called.
const actionsRepositories = {
};

class Repository {
  //Generate a repository for the resource (The /controller route with all the main and custom routes)
  constructor(resource) {
    this.resource = resource;
    this.customREST = customRESTrepositories[resource];
    this.actions = actionsRepositories[resource];
  }

  get(query) {
    var strQuery = "";
    if(query) {
      strQuery += "?";
      strQuery += Object.keys(query).map((key) => this.getPropertyQuery(key, query[key])).filter(x => x).join("&");
    }
    return Vue.prototype.$axiosMainClient.get(`${this.resource}${strQuery}`);
  }
  getPropertyQuery(key, value) {
    if(!value || (Array.isArray(value) && value.length == 0)) {
      return undefined;
    }
    if(Array.isArray(value)) {
      return key + "=" + value.join("&" + key + "=");
    }
    return key + "=" + value;
  }
  getById(id) {
    return Vue.prototype.$axiosMainClient.get(`${this.resource}/${id}`);
  }
  create(payload) {
    return Vue.prototype.$axiosMainClient.post(`${this.resource}`, payload);
  }
  update(payload, id) {
    return Vue.prototype.$axiosMainClient.put(
      `${this.resource}/${id}`,
      payload
    );
  }
  delete(id) {
    return Vue.prototype.$axiosMainClient.delete(`${this.resource}/${id}`);
  }
}

//When you get any repository with his name in parameter it will generate a repository with all the default and custom routes.
export default {
  get: (name) => {
    return new Repository(name);
  },
};
