import CalendarApiService from '@/services/calendarService';

export const state = {
  userConfig: {
    id: null,
    displayName: null,
    planificationRole: null,
    dropboxBillsVisible: null,
    dropboxContractsVisible: null,
    dropboxOthersVisible: null,
    dropboxPlansAndDrawingsVisible: null,
    dropboxPurchaseOrdersVisible: null,
    dropboxQuotesVisible: null,
    permissions: null,
    notifications: null,
    productionVisibility: null,
    progressVisibility: [],
    shippersVisibility: [],
    installersVisibility: [],
  },
  progress: [],
  installers: [],
  shippers: [],
  appointmentStatus: ['Pending', 'Started', 'Completed'],
  measureTypes: [
    'SheetsQuantity',
    'NumericValue',
    'SquareFoot',
    'SquareMeter',
    'TimePerWorkOrder',
    'DoorsQuantity',
    'Products',
    'Parts',
  ],
  alerts: {},
  calendarView: 'dayGridMonth',
  workOrderTypes: []
};

export const mutations = {
  setProgress(state, newValue) {
    state.progress = newValue;
  },
  setInstallers(state, newValue) {
    state.installers = newValue;
  },
  setShippers(state, newValue) {
    state.shippers = newValue;
  },
  setUserConfig(state, newValue) {
    state.userConfig = newValue;
  },
  setAlerts(state, newValue) {
    if (newValue != null) {
      state.alerts = newValue;
    }
  },
  setCalendarView(state, newValue) {
    state.calendarView = newValue;
  },
  setWorkOrderTypes(state, newValue) {
    localStorage.setItem('workOrderTypes', JSON.stringify(newValue));
    state.workOrderTypes = newValue;
  }
};

export const getters = {
  // Whether the user is currently logged in.
  progress(state) {
    return state.progress;
  },
  AppointmentStatus(state) {
    return state.appointmentStatus;
  },
  measureTypes(state) {
    return state.measureTypes;
  },
  userConfig(state) {
    return state.userConfig;
  },
  calendarView(state) {
    return state.calendarView;
  },
  workOrderTypes(state) {
    return JSON.parse(localStorage.getItem('workOrderTypes')) ?? state.workOrderTypes;
  }
};

export const actions = {
  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  fetchProgress({ commit, state }) {
    CalendarApiService.getAllProgress().then((response) => {
      let progress = response.data.data;
      commit('setProgress', progress);
    });
  },
  // eslint-disable-next-line no-unused-vars
  fetchInstallers({ commit, state }) {
    CalendarApiService.getInstallers().then((response) => {
      let installers = response.data.data;
      commit('setInstallers', installers);
    });
  },
  // eslint-disable-next-line no-unused-vars
  fetchShippers({ commit, state }) {
    CalendarApiService.getShippers().then((response) => {
      let shippers = response.data.data;
      commit('setShippers', shippers);
    });
  },
  fetchAlerts({ commit }) {
    CalendarApiService.getSideBarAlerts().then((response) => {
      let alerts = response.data;
      commit('setAlerts', alerts);
      return alerts;
    });
  },
};
